import { Box, InputBase, Typography } from "@mui/material";

const InputSecondary = (props) => {
  return (
    <Box
      component="div"
      sx={{ position: "relative", width: props.fullWidth ? "100%" : "auto", ...props.containerSx }}
    >
      {props.label && (
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: 16,
            fontWeight: 600,
            color: "#091B40",
            marginBottom: "18px",
          }}
        >
          {props.label}
        </Typography>
      )}
      <InputBase
        {...props}
        sx={{
          borderRadius: "15px",
          width: "100%",
          height: 57,
          paddingX: 1.5,
          display: "flex",
          alignItems: "center",
          fontFamily: "Poppins",
          fontSize: 16,
          color: "#091B40",
          backgroundColor: "#F5F6F8",
          "::placeholder": {
            opacity: 0.8,
          },
        }}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Box>
  );
};

export default InputSecondary;
