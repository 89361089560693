import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NotConnectedLayout from "../components/NotConnectedLayout";
import { useFirebase } from "../hooks/useFirebase";

const RegisterOnBoarding = () => {
  const { establishment } = useFirebase();
  const location = useLocation();
  //https://dolphin-api.fly.dev/

  //https://dolphin-api.fly.dev
  useEffect(() => {
    if (location?.state?.url) {
      setTimeout(() => {
        window.open(location.state.url, "_self");
      }, 5000);
    } else {
      axios
        .get(
          `https://dolphin-api.fly.dev/stripe/resumeSetup/${establishment.stripeAccountId}`
        )
        .then((res) => {
          if (res.data.url) {
            setTimeout(() => {
              window.open(res.data.url, "_self");
            }, 2000);
          }
        });
    }
  }, [location.state]);

  return (
    <NotConnectedLayout>
      <Box
        component="div"
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "80%",
          maxWidth: 650,
          paddingY: "60px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            marginBottom: 3,
          }}
        >
          Finalisation de votre compte
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 3,
            textAlign: "center",
          }}
        >
          Une page va s'ouvrir pour finaliser votre compte...
        </Typography>
      </Box>
    </NotConnectedLayout>
  );
};

export default RegisterOnBoarding;
