import { BarChart, StarRounded, WatchLater } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import {
  collection,
  doc,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../components/Header";
import { useFirebase } from "../hooks/useFirebase";

const Reviews = () => {
  const navigate = useNavigate();
  const { establishment, db } = useFirebase();

  const [cookingRates, setCookingRates] = useState({});
  const [presentationRates, setPresentationRates] = useState({});
  const [qualityPriceRatioRates, setQualityPriceRatioRates] = useState({});

  const totalRates = {
    1:
      cookingRates[1] +
      presentationRates[1] +
      qualityPriceRatioRates[1] / establishment.nbReviews,
    2:
      cookingRates[2] +
      presentationRates[2] +
      qualityPriceRatioRates[2] / establishment.nbReviews,
    3:
      cookingRates[3] +
      presentationRates[3] +
      qualityPriceRatioRates[3] / establishment.nbReviews,
    4:
      cookingRates[4] +
      presentationRates[4] +
      qualityPriceRatioRates[4] / establishment.nbReviews,
    5:
      cookingRates[5] +
      presentationRates[5] +
      qualityPriceRatioRates[5] / establishment.nbReviews,
  };

  useEffect(() => {
    getRates("cookingRate").then((rates) => setCookingRates(rates));
    getRates("presentationRate").then((rates) => setPresentationRates(rates));
    getRates("qualityPriceRatioRate").then((rates) =>
      setQualityPriceRatioRates(rates),
    );
  }, [establishment]);

  const getRates = async (field) => {
    let cookingRates = {};

    for (let i = 1; i <= 5; i++) {
      const cookingRatesQuery = query(
        collection(
          db,
          doc(db, `establishments/${establishment.id}`).path,
          "reviews",
        ),
        where(field, "==", i),
      );

      const result = await getCountFromServer(cookingRatesQuery);

      cookingRates[i] = result.data().count;
    }

    return cookingRates;
  };

  const DisplayRates = ({ rates, containerSx, divider = 1 }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          paddingY: 2,
          gap: 1,
          ...containerSx,
        }}
      >
        {Object.keys(rates)
          .reverse()
          .map((key) => (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#091B40",
                  width: 75,
                }}
              >
                {key} étoile{key > 1 && "s"}
              </Typography>
              <Box
                sx={{
                  marginLeft: 6,
                  flex: 1,
                  height: 14,
                  backgroundColor: "#efefef",
                  borderRadius: 9,
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: `${
                      ((rates[key] / establishment.nbReviews) * 100) / divider
                    }%`,
                    backgroundColor: "#091B40",
                    borderRadius: 9,
                  }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        sx={{
          paddingTop: 8,
          paddingLeft: 4,
          paddingRight: 8,
        }}
      />
      <Box
        sx={{
          marginTop: 6,
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          marginX: 8,
          gap: 4,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: 25,
            fontWeight: 700,
            color: "#091B40",
          }}
        >
          Notations clients
        </Typography>
        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            padding: 2.5,
            filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              paddingLeft: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <StarRounded
                sx={{
                  color: "#FFDD55",
                  width: 40,
                  height: 40,
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 33,
                  fontWeight: 700,
                  color: "#091B40",
                }}
              >
                {(
                  (establishment.totalCookingRate +
                    establishment.totalPresentationRate +
                    establishment.totalQualityPriceRatioRate) /
                    establishment.nbReviews /
                    3 || 0
                ).toFixed(1)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                marginTop: 2.5,
              }}
            >
              <BarChart sx={{ color: "#091B40", width: 14, height: 14 }} />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#091B40",
                }}
              >
                {establishment.nbReviews} avis
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                marginTop: 0.5,
              }}
            >
              <WatchLater sx={{ color: "#091B40", width: 14, height: 14 }} />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#091B40",
                }}
              >
                samedi 14 novembre
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{ width: "2px", height: "100%", backgroundColor: "#C7C8C9" }}
          />
          <DisplayRates
            rates={totalRates}
            divider={3}
            containerSx={{ paddingLeft: 8, marginRight: 1.5 }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginX: 8,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: 16,
            fontWeight: 700,
            color: "#091B40",
          }}
        >
          Détails des notations
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: 1,
              backgroundColor: "white",
              borderRadius: "15px",
              padding: 2,
              filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.09))",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: 700,
                color: "#091B40",
              }}
            >
              Cuisine
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <StarRounded
                sx={{
                  color: "#FFDD55",
                  width: 40,
                  height: 40,
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 33,
                  fontWeight: 700,
                  color: "#091B40",
                }}
              >
                {(
                  establishment.totalCookingRate / establishment.nbReviews || 0
                ).toFixed(1)}
              </Typography>
            </Box>
            <DisplayRates rates={cookingRates} containerSx={{ marginX: 1.5 }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: 1,
              backgroundColor: "white",
              borderRadius: "15px",
              padding: 2,
              filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.09))",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: 700,
                color: "#091B40",
              }}
            >
              Rapport qualité prix
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <StarRounded
                sx={{
                  color: "#FFDD55",
                  width: 40,
                  height: 40,
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 33,
                  fontWeight: 700,
                  color: "#091B40",
                }}
              >
                {(
                  establishment.totalQualityPriceRatioRate /
                    establishment.nbReviews || 0
                ).toFixed(1)}
              </Typography>
            </Box>
            <DisplayRates
              rates={qualityPriceRatioRates}
              containerSx={{ marginX: 1.5 }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: 1,
              backgroundColor: "white",
              borderRadius: "15px",
              padding: 2,
              filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.09))",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: 700,
                color: "#091B40",
              }}
            >
              Présentation
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <StarRounded
                sx={{
                  color: "#FFDD55",
                  width: 40,
                  height: 40,
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 33,
                  fontWeight: 700,
                  color: "#091B40",
                }}
              >
                {(
                  establishment.totalPresentationRate /
                    establishment.nbReviews || 0
                ).toFixed(1)}
              </Typography>
            </Box>
            <DisplayRates
              rates={presentationRates}
              containerSx={{ marginX: 1.5 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Reviews;
