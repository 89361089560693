import { ButtonBase } from "@mui/material";

const Button = ({ fullWidth, label, size = "base", sx, ...others }) => {
  const heightBySize = {
    base: 78,
    small: 54,
  };

  return (
    <ButtonBase
      {...others}
      sx={{
        backgroundColor: "#FE765E",
        borderRadius: 60,
        width: fullWidth ? "100%" : "auto",
        paddingX: 2,
        height: heightBySize[size],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Poppins",
        fontSize: 20,
        color: "#fff",
        ...sx,
      }}
    >
      {label}
    </ButtonBase>
  );
};

export default Button;
