import { AddAPhotoRounded, CloseRounded } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Header from "../components/Header";
import Button from "../components/ui/Button";
import InputSecondary from "../components/ui/InputSecondary";
import SwitchSecondary from "../components/ui/SwitchSecondary";
import { useFirebase } from "../hooks/useFirebase";
import formReducer from "../utils/formReducer";
import { useSnackbar } from "notistack";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import ComboBox from "../components/ui/ComboBox";
import TableDragAndDropList from "../components/TableDragAndDropList";

const Product = () => {
  const navigate = useNavigate();
  const { db, storage, establishment, establishmentRef } = useFirebase();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();

  const [product, dispatch] = useReducer(formReducer, {});
  const [image, setImage] = useState(null);

  const [haveSizes, setHaveSizes] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [sizeName, setSizeName] = useState("");
  const [sizeExtraCost, setSizeExtraCost] = useState("");

  const [haveCustomizations, setHaveCustomizations] = useState(false);
  const [customizations, setCustomizations] = useState([]);
  const [customizationName, setCustomizationName] = useState("");
  const [customizationExtraCost, setCustomizationExtraCost] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      const product = await getDoc(
        doc(db, establishmentRef.path, "products", params.id)
      );
      dispatch({
        type: "set",
        payload: {
          ...product.data(),
          ingredients: product.data().ingredients.join(", "),
        },
      });
      setImage({ url: product.data().image });
      setHaveSizes(product.data().sizes && product.data().sizes.length > 0);
      setSizes(product.data().sizes || []);
      setHaveCustomizations(
        product.data().customizations &&
          product.data().customizations.length > 0
      );
      setCustomizations(product.data().customizations || []);
    };

    if (params?.id) fetchProducts();
  }, []);

  const askDelete = () => {
    enqueueSnackbar(
      "Voulez vous vraiment supprimer ce produit ? Cette action est irréversible.",
      {
        variant: "warning",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
        persist: true,
        preventDuplicate: true,
        action: (key) => (
          <>
            <ButtonBase
              sx={{
                backgroundColor: "#bbbbbd",
                padding: 1,
                borderRadius: 1,
              }}
              onClick={() => closeSnackbar(key)}
            >
              Annuler
            </ButtonBase>
            <ButtonBase
              onClick={() => handleDelete(key)}
              sx={{
                marginLeft: 2,
                marginRight: 2,
                backgroundColor: "red",
                padding: 1,
                borderRadius: 1,
              }}
            >
              Supprimer
            </ButtonBase>
          </>
        ),
      }
    );
  };

  const handleDelete = async (key) => {
    await deleteDoc(doc(db, establishmentRef.path, "products", params.id));
    closeSnackbar(key);
    navigate(-1);
    enqueueSnackbar("Produit supprimé", {
      variant: "success",
    });
  };

  const handleSubmit = async () => {
    if (!image) {
      enqueueSnackbar("Veuillez ajouter une image", { variant: "error" });
      return;
    } else if (!product.name) {
      enqueueSnackbar("Veuillez ajouter un nom", { variant: "error" });
      return;
    } else if (!product.price) {
      enqueueSnackbar("Veuillez ajouter un prix", { variant: "error" });
      return;
    }

    let imageURL;
    if (product.image !== image?.url) {
      imageURL = await uploadFile(
        image.file,
        establishmentRef,
        product.name + image.type.replace("image/", ".")
      );
    }

    const formattedIngredients = product.ingredients
      .split(",")
      .map((ingredient) => ingredient.trim());
    const newProduct = {
      ...product,
      category: product.category,
      price: +product.price,
      ingredients:
        formattedIngredients.length === 1 && !formattedIngredients[0]
          ? []
          : formattedIngredients,
      sizes: haveSizes ? sizes : [],
      customizations: haveCustomizations ? customizations : [],
      image: imageURL || product.image,
    };

    const productsRef = collection(db, establishmentRef.path, "products");

    if (params?.id) {
      const productRef = doc(db, establishmentRef.path, "products", params.id);
      await updateDoc(productRef, newProduct);
    } else {
      await addDoc(productsRef, newProduct);
    }

    await updatedEstablishment();

    enqueueSnackbar(
      `Votre produit a bien été ${params?.id ? "modifié" : "créé"}`,
      {
        variant: "success",
      }
    );
    navigate("/establishment");
  };

  const updatedEstablishment = async () => {
    const hasVegetarian = await getDocs(
      query(
        collection(db, establishmentRef.path, "products"),
        where("isVegetarian", "==", true)
      )
    );
    const hasVegan = await getDocs(
      query(
        collection(db, establishmentRef.path, "products"),
        where("isVegan", "==", true)
      )
    );

    await updateDoc(establishmentRef, {
      hasVegetarian: hasVegetarian.docs.length > 0,
      hasVegan: hasVegan.docs.length > 0,
      productCategories: establishment.productCategories.includes(
        product.category
      )
        ? establishment.productCategories
        : [...establishment.productCategories, product.category],
    });
  };

  const uploadFile = async (file, docRef, filename = file.name) => {
    try {
      const storageRef = ref(storage, `${docRef.path}/products/${filename}`);

      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      return Promise.resolve(url);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    const url = URL.createObjectURL(file);

    setImage({ file: file, url: url, type: file.type });
  };

  const handleAddSize = () => {
    if (sizeName) {
      const newSize = { name: sizeName };
      if (sizeExtraCost) newSize.extraCost = +sizeExtraCost;
      setSizes([...sizes, newSize]);
      setSizeName("");
      setSizeExtraCost(0);
    }
  };

  const handleRemoveSize = (index) => {
    const newSizes = [...sizes];
    newSizes.splice(index, 1);
    setSizes(newSizes);
  };

  const handleAddCustomization = () => {
    if (customizationName) {
      setCustomizations([
        ...customizations,
        { name: customizationName, extraCost: +customizationExtraCost },
      ]);
      setCustomizationName("");
      setCustomizationExtraCost("");
    }
  };

  const handleRemoveCustomization = (index) => {
    const newCustomizations = [...customizations];
    newCustomizations.splice(index, 1);
    setCustomizations(newCustomizations);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        sx={{
          paddingTop: 8,
          paddingLeft: 4,
          paddingRight: 8,
        }}
      />
      <Stack
        direction={{ xs: "column", lg: "row" }}
        spacing={4}
        justifyContent="space-between"
        sx={{
          height: "100%",
          overflowY: "auto",
          paddingLeft: 4,
          paddingRight: 8,
          paddingBottom: 8,
          marginTop: 4,
          paddingTop: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "fit-content",
            marginBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
              borderRadius: "30px",
              paddingX: 4,
              paddingY: 6,
              gap: 4,
            }}
          >
            <Box
              onClick={() => navigate("/establishment")}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                cursor: "pointer",
                width: "fit-content",
              }}
            >
              <img src="/svg/arrow-back-orange.svg" alt="food" />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#FE765E",
                }}
              >
                Menus
              </Typography>
            </Box>
            <InputSecondary
              label="Nom du produit"
              fullWidth
              value={product?.name || ""}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "name",
                  payload: e.target.value,
                })
              }
            />
            <InputSecondary
              label="Description"
              fullWidth
              value={product?.description || ""}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "description",
                  payload: e.target.value,
                })
              }
            />
            <ComboBox
              label="Catégorie"
              fullWidth
              value={product?.category || ""}
              options={establishment?.productCategories || []}
              onChange={(value) => {
                dispatch({
                  type: "field",
                  field: "category",
                  payload: value,
                });
              }}
            />
            {/* <Autocomplete
              value={product?.category || ""}
              onChange={(event, newValue) => {
                dispatch({
                  type: "field",
                  field: "category",
                  payload: newValue.replace("Ajouter ", ""),
                });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                console.log(params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== "" && !isExisting) {
                  filtered.push("Ajouter " + inputValue);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={establishment?.productCategories}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option;
                }
                // Regular option
                return option;
              }}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              sx={{
                borderRadius: "15px",
                height: 57,
                display: "flex",
                alignItems: "center",
                fontFamily: "Poppins",
                fontSize: 16,
                color: "#091B40",
                backgroundColor: "#F5F6F8",
                "::placeholder": {
                  opacity: 0.8,
                },
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "100%",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    color: "#091B40",
                  }}
                />
              )}
            /> */}
            <InputSecondary
              label="Ingrédients (séparés par une virgule)"
              fullWidth
              value={product?.ingredients || ""}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "ingredients",
                  payload: e.target.value,
                })
              }
            />
            <SwitchSecondary
              label="Ce plat est végétarien"
              checked={product?.isVegetarian || false}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "isVegetarian",
                  payload: e.target.checked,
                })
              }
            />
            <SwitchSecondary
              label="Ce plat est végan"
              checked={product?.isVegan || false}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "isVegan",
                  payload: e.target.checked,
                })
              }
            />
            <InputSecondary
              label="Prix"
              fullWidth
              type="number"
              value={product?.price || ""}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "price",
                  payload: e.target.value,
                })
              }
            />
            <SwitchSecondary
              label="Publié"
              checked={product?.isPublish || false}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "isPublish",
                  payload: e.target.checked,
                })
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            // maxWidth: 645,
            height: "fit-content",
            flexDirection: "column",
          }}
        >
          <input
            type="file"
            id="file"
            hidden
            accept="image/*"
            onChange={handleImageUpload}
          />
          <label
            htmlFor="file"
            style={{
              width: "100%",
              maxHeight: 387,
              backgroundColor: "white",
              border: "5px solid #FE765E",
              borderRadius: "15px",
              borderBottomRightRadius: 0,
              position: "relative",
              cursor: "pointer",
            }}
          >
            {image?.url ? (
              <img
                src={image?.url}
                alt={product?.name || "product"}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: 316,
                  borderRadius: "9px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: 316,
                  borderRadius: "9px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F5F5F5",
                }}
              >
                Uploader une image
              </Box>
            )}

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 117,
                height: 95,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FE765E",
                borderTopLeftRadius: "60px",
              }}
            >
              <AddAPhotoRounded
                sx={{ color: "white", width: 40, height: 40 }}
              />
            </Box>
          </label>

          {/* customisations */}
          {/* sizes */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
              borderRadius: "30px",
              paddingX: 4,
              paddingY: 6,
              gap: 4,
              marginTop: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 18,
                  fontWeight: 600,
                  color: "#091B40",
                }}
              >
                Tailles
              </Typography>
              <SwitchSecondary
                checked={haveSizes}
                onChange={(e) => setHaveSizes(e.target.checked)}
              />
            </Box>
            {haveSizes && (
              <Stack direction="column" spacing={4}>
                <Stack
                  direction={{
                    xs: "column",
                    lg: "column",
                    xl: "row",
                  }}
                  alignItems={{ xs: "flex-start", xl: "flex-end" }}
                  spacing={{ xs: 2, lg: 4 }}
                >
                  <InputSecondary
                    fullWidth
                    label="Nom"
                    value={sizeName}
                    onChange={(e) => setSizeName(e.target.value)}
                  />
                  <InputSecondary
                    fullWidth
                    label="Coût supplémentaire"
                    value={sizeExtraCost}
                    type="number"
                    onChange={(e) => setSizeExtraCost(e.target.value)}
                  />
                </Stack>
                <Button
                  fullWidth
                  label="Ajouter"
                  onClick={handleAddSize}
                  size="small"
                />

                {sizes.length > 0 ? (
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nom de la taille</TableCell>
                          <TableCell>Coût supplémentaire</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableDragAndDropList
                        items={sizes}
                        onItemsChange={setSizes}
                        dragabbleKeyName="name"
                        containerSx={{}}
                        renderItem={(item, props, index) => (
                          <TableRow {...props}>
                            <TableCell sx={{ fontSize: 16, color: "#091B40" }}>
                              {item.name}
                            </TableCell>
                            <TableCell sx={{ fontSize: 16, color: "#091B40" }}>
                              {item.extraCost || "Aucun"}
                            </TableCell>
                            <TableCell onClick={() => handleRemoveSize(index)}>
                              <CloseRounded sx={{ color: "#FE765E" }} />
                            </TableCell>
                          </TableRow>
                        )}
                      />
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography
                    textAlign={{ xs: "center", xl: "left" }}
                    sx={{ fontSize: 16, color: "#091B40" }}
                  >
                    Aucune taille ajoutée
                  </Typography>
                )}
              </Stack>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 18,
                  fontWeight: 600,
                  color: "#091B40",
                }}
              >
                Customisations
              </Typography>
              <SwitchSecondary
                checked={haveCustomizations}
                onChange={(e) => setHaveCustomizations(e.target.checked)}
              />
            </Box>
            {haveCustomizations && (
              <Stack direction="column" spacing={4}>
                <Stack
                  direction={{
                    xs: "column",
                    lg: "column",
                    xl: "row",
                  }}
                  alignItems={{ xs: "flex-start", xl: "flex-end" }}
                  spacing={{ xs: 2, lg: 4 }}
                >
                  <InputSecondary
                    fullWidth
                    label="Nom"
                    value={customizationName}
                    onChange={(e) => setCustomizationName(e.target.value)}
                  />
                  <InputSecondary
                    fullWidth
                    label="Coût supplémentaire"
                    value={customizationExtraCost}
                    type="number"
                    onChange={(e) => setCustomizationExtraCost(e.target.value)}
                  />
                </Stack>
                <Button
                  fullWidth
                  label="Ajouter"
                  onClick={handleAddCustomization}
                  size="small"
                />

                {customizations.length > 0 ? (
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nom de la taille</TableCell>
                          <TableCell>Coût supplémentaire</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customizations.map((customization, index) => (
                          <TableRow key={customization.name}>
                            <TableCell sx={{ fontSize: 16, color: "#091B40" }}>
                              {customization.name}
                            </TableCell>
                            <TableCell sx={{ fontSize: 16, color: "#091B40" }}>
                              {customization.extraCost || "Aucun"}
                            </TableCell>
                            <TableCell
                              onClick={() => handleRemoveCustomization(index)}
                            >
                              <CloseRounded sx={{ color: "#FE765E" }} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography
                    textAlign={{ xs: "center", xl: "left" }}
                    sx={{ fontSize: 16, color: "#091B40" }}
                  >
                    Aucune customisation ajoutée
                  </Typography>
                )}
              </Stack>
            )}
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              label="Supprimer"
              sx={{ width: 168, marginTop: 8, backgroundColor: "red" }}
              size="small"
              onClick={askDelete}
            />
            <Button
              label="Enregistrer"
              sx={{ width: 168, marginLeft: 5, marginTop: 8 }}
              size="small"
              onClick={handleSubmit}
            />
          </div>
        </Box>
      </Stack>
    </Box>
  );
};

export default Product;
