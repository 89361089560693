import React, { useEffect, useReducer, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFirebase } from "../hooks/useFirebase";
import formReducer from "../utils/formReducer";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import NotConnectedLayout from "../components/NotConnectedLayout";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Select from "../components/ui/Select";
import axios from "axios";
import { useCookies } from "react-cookie";
import InputPlacesAutocomplete from "../components/ui/InputPlacesAutocomplete";

const Register = () => {
  const { auth, db } = useFirebase();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [cookies, setCookie] = useCookies();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [state, dispatch] = useReducer(formReducer, {
    lastname: "",
    firstname: "",
    email: "",
    phone: "+33",
    establishmentName: "",
    address: "",
    establishmentType: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await getDocs(collection(db, "categories"));
      setCategories(
        categories.docs.map((doc) => ({
          label: doc.data().title,
          value: doc.data().value,
        }))
      );
    };

    fetchCategories();
  }, []);

  const onSubmit = async () => {
    if (isLoading) return;
    try {
      if (!state.lastname) {
        enqueueSnackbar("Merci de renseigner un nom !", {
          variant: "error",
        });
        return;
      }
      if (!state.firstname) {
        enqueueSnackbar("Merci de renseigner un prénom !", {
          variant: "error",
        });
        return;
      }
      if (!state.email) {
        enqueueSnackbar("Merci de renseigner un email !", {
          variant: "error",
        });
        return;
      }
      if (!state.phone) {
        enqueueSnackbar("Merci de renseigner un numéro de téléphone !", {
          variant: "error",
        });
        return;
      }
      if (!state.establishmentName) {
        enqueueSnackbar("Merci de renseigner un nom de restaurant !", {
          variant: "error",
        });
        return;
      }
      if (!state.address || !state._geoloc) {
        enqueueSnackbar(
          "Merci de renseigner une adresse avec l'autocompletion !",
          {
            variant: "error",
          }
        );
        return;
      }
      if (state.password < 3) {
        enqueueSnackbar("Merci de renseigner un mot de passe !", {
          variant: "error",
        });
        return;
      }

      setIsLoading(true);
      setIsSubmitting(true);
      const response = await createUserWithEmailAndPassword(
        auth,
        state.email,
        state.password
      );

      const establishment = {
        name: state.establishmentName,
        address: state.address,
        _geoloc: {
          lat: +state._geoloc.lat,
          lng: +state._geoloc.lng,
        },
        productCategories: [],
        preparationTime: [],
        averagePrice: 1,
        hasVegetarian: false,
        hasVegan: false,
        nbReviews: 0,
        totalCookingRate: 0,
        totalPresentationRate: 0,
        totalQualityPriceRatioRate: 0,
        email: state.email,
        phone: state.phone,
        establishmentType: state.establishmentType,
        status: "pending",
        schedules: [],
      };
      const establishmentRef = doc(db, "establishments", response.user.uid);
      await setDoc(establishmentRef, establishment);

      // navigate("/register-success");
      //https://dolphin-api.fly.dev
      const stripeResponse = await axios.post(
        `https://dolphin-api.fly.dev/stripe/createAccount/${state.email}`,
        {
          name: state.establishmentName,
          phone: state.phone,
          firstName: state.firstName,
          lastName: state.lastName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (stripeResponse.data) {
        enqueueSnackbar("Votre compte a bien été créé !", {
          variant: "success",
        });
        await updateDoc(establishmentRef, {
          stripeAccountId: stripeResponse.data.account,
          status: "onboarding",
        });

        setCookie("establishmentToken", response.user.uid, {
          path: "/",
          maxAge: 86400,
        });

        setIsLoading(false);
        setIsSubmitting(false);
        // navigate("/register-onboarding", {
        //   state: stripeResponse.data,
        // });
        navigate("/dashboard");
      } else {
        enqueueSnackbar("Une erreur est survenue !", {
          variant: "error",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setIsSubmitting(false);
      if (error.code === "auth/email-already-in-use") {
        enqueueSnackbar("Un compte est déjà associé à cette email !", {
          variant: "error",
        });
        return;
      }
      console.log(error);
      enqueueSnackbar("Une erreur est survenue !", {
        variant: "error",
      });
      return;
    }
  };

  return (
    <NotConnectedLayout>
      <Box
        component="div"
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "80%",
          maxWidth: 650,
          paddingY: "60px",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          style={{
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: 50,
            color: "#091B40",
            marginBottom: "40px",
          }}
        >
          Devenir partenaire
        </Typography>

        <Box
          sx={{
            marginTop: "44px",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Input
              label="Nom"
              placeholder="Entrez votre nom de famille"
              type="text"
              value={state.lastname}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "lastname",
                  payload: e.target.value,
                })
              }
            />
            <Input
              label="Prénom"
              placeholder="Entrez votre prénom"
              type="firstname"
              value={state.firstname}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "firstname",
                  payload: e.target.value,
                })
              }
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Input
              label="Adresse Email"
              placeholder="Entrez votre email"
              type="mail"
              value={state.email}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "email",
                  payload: e.target.value,
                })
              }
            />
            <Input
              label="N° de téléphone"
              type="tel"
              value={state.phone}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "phone",
                  payload: e.target.value,
                })
              }
            />
          </Box>
          <Input
            label="Nom de l'établissement"
            placeholder="Entrez le nom de votre établissement"
            type="text"
            value={state.establishmentName}
            onChange={(e) =>
              dispatch({
                type: "field",
                field: "establishmentName",
                payload: e.target.value,
              })
            }
          />
          <InputPlacesAutocomplete
            label="Adresse"
            placeholder="Entrez votre adresse"
            value={state?.address || ""}
            onSelect={(address) => {
              dispatch({
                type: "field",
                field: "address",
                payload: address.name,
              });
              dispatch({
                type: "field",
                field: "_geoloc",
                payload: address.location,
              });
            }}
          />
          <Select
            label="Type d'établissement"
            options={categories}
            value={state.establishmentType}
            onChange={(e) =>
              dispatch({
                type: "field",
                field: "establishmentType",
                payload: e.target.value,
              })
            }
          />

          <Input
            label="Mot de passe"
            placeholder="Entrez votre mot de passe"
            type="password"
            value={state.password}
            onChange={(e) =>
              dispatch({
                type: "field",
                field: "password",
                payload: e.target.value,
              })
            }
          />
        </Box>

        <Typography
          component="p"
          variant="body1"
          style={{
            marginTop: "10px",
            fontFamily: "Poppins",
            fontSize: 16,
            color: "#091B40",
          }}
        >
          En cliquant sur Envoyer, vous acceptez les{" "}
          <span
            onClick={() => navigate("/general-conditions")}
            style={{
              color: "#FE765E",
              cursor: "pointer",
            }}
          >
            Conditions générales
          </span>{" "}
          de Dolphin deliv et confirmez que vous avez lu la{" "}
          <span
            onClick={() => navigate("/privacy-policy")}
            style={{
              color: "#FE765E",
              cursor: "pointer",
            }}
          >
            Politique de confidentialité
          </span>
          .
        </Typography>

        {isSubmitting ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <CircularProgress
              size={50}
              sx={{
                color: "rgba(0, 0, 0, 0.8)",
              }}
            />
          </div>
        ) : (
          <Button
            type="submit"
            variant="contained"
            label="Envoyer"
            color="primary"
            onClick={onSubmit}
            sx={{
              marginTop: "32px",
              pointerEvents: isSubmitting ? "none" : "auto",
            }}
            fullWidth
          />
        )}
        <Typography
          component="p"
          variant="body1"
          style={{
            fontFamily: "Poppins",
            fontSize: 16,
            color: "#091B40",
            marginTop: "60px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 5,
          }}
        >
          Vous avez déjà un compte ?{" "}
          <Typography
            onClick={() => navigate("/login")}
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: 16,
              color: "#FE765E",
              cursor: "pointer",
            }}
          >
            Se connecter
          </Typography>
        </Typography>
      </Box>
    </NotConnectedLayout>
  );
};

export default Register;
