import { Box, Typography } from "@mui/material";
import { NativeSelect } from "@mui/material";

const Select = ({ options, value, onChange, label, containerSx, showLabel = true }) => {
  return (
    <Box component="div" sx={{ position: "relative", ...containerSx }}>
      {label && showLabel && (
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: 20,
            fontWeight: 700,
            color: "#091B40",
            marginBottom: "21px",
          }}
        >
          {label}
        </Typography>
      )}
      <NativeSelect
        value={value}
        variant="outlined"
        onChange={onChange}
        sx={{
          border: 2,
          borderColor: "#091B40",
          borderRadius: 60,
          width: "100%",
          height: 69,
          paddingY: 3,
          paddingX: 5,
          fontFamily: "Poppins",
          fontSize: 16,
          color: "#091B40",
          "::placeholder": {
            opacity: 0.8,
          },
          ":before": {
            borderBottom: "none",
          },
          ":after": {
            borderBottom: "none",
          },
          ":hover:not(.Mui-disabled, .Mui-error)::before" : {
            borderBottom: "none",
          },
        }}
      >
        <option value="">{label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </Box>
  );
};

export default Select;
