import { Box, Typography } from "@mui/material";
import { format, isMonday, previousMonday } from "date-fns";
import { fr } from "date-fns/locale";
import {
  collection,
  doc,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import BarChart from "../components/ChartComponent";
import Header from "../components/Header";
import LastOrderCard from "../components/LastOrderCard";
import { useFirebase } from "../hooks/useFirebase";
import ModalStripe from "../components/ModalStripe";
import axios from "axios";

const Dashboard = () => {
  const navigate = useNavigate();
  const { establishment, db } = useFirebase();
  const [lastOrders, setLastOrders] = useState([]);
  const [todayTotalOrders, setTodayTotalOrders] = useState(0);
  const [nbOrdersLastWeek, setNbOrdersLastWeek] = useState([]);
  const [totalEarnedToday, setTotalEarnedToday] = useState(0);

  useEffect(() => {
    let unsubscribeLastOrders;
    let unsubscribeTotalEarnedToday;

    const getLastOrders = async () => {
      const lastOrdersQuery = query(
        collection(db, "orders"),
        where(
          "establishmentRef",
          "==",
          doc(db, `establishments/${establishment.id}`)
        ),
        orderBy("date", "desc"),
        limit(10)
      );

      unsubscribeLastOrders = onSnapshot(lastOrdersQuery, (snapshot) => {
        const lastOrders = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLastOrders(lastOrders);
      });
    };
    const getTotalEarnedToday = () => {
      const EarnedTodayQuery = query(
        collection(db, "orders"),
        where(
          "establishmentRef",
          "==",
          doc(db, `establishments/${establishment.id}`)
        ),
        where("date", ">=", new Date(new Date().setHours(0, 0, 0, 0))),
        where("date", "<=", new Date(new Date().setHours(23, 59, 59, 999))),
        orderBy("date", "desc")
      );

      async function fetchBalance() {
        const response = await axios.get(
          `https://dolphin-api.fly.dev/stripe/wallet/` +
            establishment.stripeAccountId
        );
        setTotalEarnedToday((response.data.balance / 100).toFixed(2));
      }

      fetchBalance();
    };
    const countTodayTotalOrders = async () => {
      const todayOrdersQuery = query(
        collection(db, "orders"),
        where(
          "establishmentRef",
          "==",
          doc(db, `establishments/${establishment.id}`)
        ),
        where("date", ">=", new Date(new Date().setHours(0, 0, 0, 0))),
        where("date", "<=", new Date(new Date().setHours(23, 59, 59, 999)))
      );

      const snapshot = await getCountFromServer(todayOrdersQuery);

      setTodayTotalOrders(snapshot.data().count);
    };

    getLastOrders();
    getTotalEarnedToday();
    countTodayTotalOrders();
    getNumberOfOrdersLastWeek();

    return () => {
      unsubscribeLastOrders && unsubscribeLastOrders();
      unsubscribeTotalEarnedToday && unsubscribeTotalEarnedToday();
    };
  }, []);

  const getNumberOfOrdersLastWeek = async () => {
    let monday;
    if (isMonday(new Date())) {
      monday = new Date();
    } else {
      monday = previousMonday(new Date());
    }

    // create an array with the last 7 days
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(
        monday.getFullYear(),
        monday.getMonth(),
        monday.getDate() + i
      );

      const dayOrdersQuery = query(
        collection(db, "orders"),
        where(
          "establishmentRef",
          "==",
          doc(db, `establishments/${establishment.id}`)
        ),
        where("date", ">=", new Date(day.setHours(0, 0, 0, 0))),
        where("date", "<=", new Date(day.setHours(23, 59, 59, 999)))
      );

      const snapshot = await getCountFromServer(dayOrdersQuery);

      days.push({
        label: format(day, "EEEE", { locale: fr }),
        value: snapshot.data().count,
      });
    }

    setNbOrdersLastWeek(days);
  };

  return (
    <>
      <ModalStripe />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          sx={{
            paddingTop: 8,
            paddingLeft: 4,
            paddingRight: 8,
          }}
        />

        <Box
          sx={{
            height: "100%",
            paddingLeft: 4,
            paddingRight: 8,
            paddingBottom: 8,
            marginTop: 2,
            display: "flex",
            flexDirection: "row",
            gap: 6,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 20,
                marginBottom: 3,
              }}
            >
              Aujourd'hui
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Box
                sx={{
                  backgroundColor: "#091B40",
                  borderRadius: "30px",
                  padding: 3,
                  display: "flex",
                  flexDirection: "column",
                  itemsAlign: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: 25,
                    color: "white",
                  }}
                >
                  Bienvenue,
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Nous vous souhaitons une bonne journée de travail
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "30px",
                  padding: 3,
                  display: "flex",
                  flexDirection: "column",
                  itemsAlign: "center",
                  justifyContent: "center",
                  gap: 2,
                  filter: "drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.12))",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2.5,
                  }}
                >
                  <img
                    src="/svg/wallet-blue.svg"
                    alt="wallet"
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#091B40",
                    }}
                  >
                    Porte monnaie
                  </Typography>
                </Box>
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: 30,
                    color: "#091B40",
                    textAlign: "center",
                  }}
                >
                  {totalEarnedToday} €
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "30px",
                  padding: 3,
                  display: "flex",
                  flexDirection: "column",
                  itemsAlign: "center",
                  justifyContent: "center",
                  gap: 2,
                  filter: "drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.12))",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2.5,
                  }}
                >
                  <img
                    src="/svg/order-blue.svg"
                    alt="wallet"
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#091B40",
                    }}
                  >
                    Commandes
                  </Typography>
                </Box>
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: 30,
                    color: "#091B40",
                    textAlign: "center",
                  }}
                >
                  {todayTotalOrders}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "400px",
                marginTop: 4,
                backgroundColor: "white",
                borderRadius: "30px",
                padding: 3,
                filter: "drop-shadow(0px 3px 30px rgba(0, 0, 0, 0.12))",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#091B40",
                  // marginBottom: 2,
                }}
              >
                Nombre de commandes sur la semaine
              </Typography>
              {nbOrdersLastWeek && (
                <BarChart data={nbOrdersLastWeek} height={200} />
              )}

              {/* <Box sx={{ width: "50%", marginLeft: 3 }}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#091B40",
                  marginBottom: 2,
                }}
              >
                CA sur la semaine
              </Typography>
              <BarChart />
            </Box> */}
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: 316,
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: 20,
                  color: "#091B40",
                }}
              >
                Dernière commandes
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#FE765E",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/orders")}
              >
                Voir plus
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                marginTop: 4,
              }}
            >
              {lastOrders.length > 0 ? (
                lastOrders.map((order, index) => (
                  <LastOrderCard order={order} key={index} />
                ))
              ) : (
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 16,
                    textAlign: "center",
                  }}
                >
                  Aucune commande
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
