import { initializeApp } from "firebase/app";
import { initializeAuth, browserLocalPersistence } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { useContext } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";

const firebaseConfig = {
  apiKey: "AIzaSyB02yBf2pN598Apv2qXwWKqLFB6Pzf0v74",

  authDomain: "dolphin-deliv.firebaseapp.com",

  projectId: "dolphin-deliv",

  storageBucket: "dolphin-deliv.appspot.com",

  messagingSenderId: "243287965202",

  appId: "1:243287965202:web:ea1948ad0e012cc29a22cb",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
});
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export const FirebaseContext = createContext();

export const useFirebase = () => useContext(FirebaseContext);

const FirebaseContextProvider = (props) => {
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(true);
  const [establishment, setEstablishment] = useState(null);
  const [products, setProducts] = useState([]);
  const [establishmentRef, setEstablishmentRef] = useState(null);

  useEffect(() => {
    if (cookies?.establishmentToken) {
      setIsLoading(true);
      const establishmentRef = doc(
        db,
        "establishments",
        cookies["establishmentToken"]
      );
      setEstablishmentRef(establishmentRef);

      const unsub = onSnapshot(
        establishmentRef,
        { includeMetadataChanges: true },
        (doc) => {
          setEstablishment({ id: doc.id, ...doc.data() });
          setIsLoading(false);
        }
      );
      const unsubProducts = onSnapshot(
        query(collection(db, establishmentRef.path, "products")),
        { includeMetadataChanges: true },
        (querySnapshot) => {
          setProducts(
            querySnapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
            })
          );
          setIsLoading(false);
        }
      );

      return () => {
        unsub();
        unsubProducts();
      };
    } else {
      setIsLoading(false);
    }
  }, [cookies]);

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <FirebaseContext.Provider
      value={{
        auth,
        db,
        establishment,
        setEstablishment,
        establishmentRef,
        storage,
        products,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContextProvider;
