import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import Header from "../components/Header";
import Select from "../components/ui/Select";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import { useFirebase } from "../hooks/useFirebase";
import formReducer from "../utils/formReducer";
import { useReducer, useState } from "react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

const Account = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { establishment, establishmentRef, db } = useFirebase();
  const [state, dispatch] = useReducer(formReducer, {});

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await getDocs(collection(db, "categories"));
      setCategories(categories.docs.map((doc) => ({ label: doc.data().title, value: doc.data().value })));
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (establishment) {
      dispatch({
        type: "set",
        payload: {
          lastname: establishment?.lastname,
          firstname: establishment?.firstname,
          email: establishment?.email,
          phone: establishment?.phone,
          name: establishment?.name,
          establishmentType: establishment?.establishmentType,
        },
      });
    }
  }, [establishment]);

  const handleSubmit = () => {
    updateDoc(establishmentRef, {
      ...state,
      establishmentType: state?.establishmentType,
    });

    enqueueSnackbar("Votre compte a bien été mis à jour", {
      variant: "success",
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        sx={{
          paddingTop: 8,
          paddingLeft: 4,
          paddingRight: 8,
        }}
      />
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          paddingLeft: 4,
          paddingRight: 8,
          paddingBottom: 8,
          paddingTop: 8,
          display: "flex",
          flexDirection: "row",
          gap: 4,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
                borderRadius: "30px",
                paddingX: 4,
                paddingY: 6,
                gap: 4,
                marginBottom: 4,
              }}
            >
              <Box
                onClick={() => {}}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                  width: "100%",
                }}
              >
                <Input
                  placeholder="Entrez votre nom"
                  label="Nom"
                  value={state?.lastname}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      field: "lastname",
                      payload: e.target.value,
                    })
                  }
                  fullWidth
                />
                <Input
                  placeholder="Entrez votre prénom"
                  label="Prénom"
                  value={state?.firstname}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      field: "firstname",
                      payload: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Box>
              <Input
                placeholder="Entrez votre adresse mail"
                type="email"
                label="Email"
                value={state?.email}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "email",
                    payload: e.target.value,
                  })
                }
                fullWidth
              />
              <Input
                defaultValue="+33"
                type="tel"
                label="N° de téléphone"
                value={state?.phone}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "phone",
                    payload: e.target.value,
                  })
                }
                fullWidth
              />
              <Input
                label="Nom de l'établissement"
                value={state?.name}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "name",
                    payload: e.target.value,
                  })
                }
                fullWidth
              />
              <Select
                label="Type d'établissement"
                options={categories}
                value={state?.establishmentType}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "establishmentType",
                    payload: e.target.value,
                  })
                }
              />
              <Button
                onClick={handleSubmit}
                label="Enregistrer"
                sx={{
                  width: 310,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: 482,
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              backgroundColor: "white",
              filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
              borderRadius: "30px",
              paddingX: 4,
              paddingY: 6,
            }}
          >
            <Box
              onClick={() => {}}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img src="/svg/trash-orange.svg" alt="profile" />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#FE765E",
                  marginLeft: 2,
                }}
              >
                Supprimer mon compte
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Account;
