import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { useCookies } from "react-cookie";
import Login from "../pages/Login";
import ConnectedLayout from "../components/ConnectedLayout";
import Register from "../pages/Register";
import RegisterSuccess from "../pages/RegisterSuccess";
import Dashboard from "../pages/Dashboard";
import Establishment from "../pages/Establishment";
import Account from "../pages/Account";
import Product from "../pages/Product";
import EditEstablishment from "../pages/EditEstablishment";
import Reviews from "../pages/Reviews";
import { useFirebase } from "../hooks/useFirebase";
import RegisterOnBoarding from "../pages/RegisterOnboardStripe";
import Orders from "../pages/Orders";
import ForgotPassword from "../pages/ForgotPassword";

const AuthRedirection = ({ component }) => {
  const [cookies] = useCookies();

  return !!cookies.establishmentToken ? (
    <Navigate to="/dashboard" replace />
  ) : (
    component
  );
};

const PrivateRoute = ({ component }) => {
  const [cookies] = useCookies();
  const { establishment } = useFirebase();
  const location = useLocation();

  /*  if (
    !!cookies.establishmentToken  &&
    establishment?.status === "onboarding" &&
    location.pathname !== "/register-onboarding"
  ) {
    return <Navigate to="/register-onboarding" replace />;
  } else if (
    !!cookies.establishmentToken &&
    establishment?.status === "active" &&
    location.pathname === "/register-onboarding"
   
  ) {
    return <Navigate to="/dashboard" replace />;
  } else  */ if (!!cookies.establishmentToken) {
    return component;
  } else {
    return <Navigate to="/login" replace />;
  }
};

const Router = () => {
  return (
    <Routes>
      {/* route without auth */}
      <Route
        index
        exact
        path="/login"
        element={<AuthRedirection component={<Login />} />}
      />
      <Route
        exact
        path="/register"
        element={<AuthRedirection component={<Register />} />}
      />
      <Route
        exact
        path="/register-success"
        element={<AuthRedirection component={<RegisterSuccess />} />}
      />
      <Route
        exact
        path="/forgot"
        element={<AuthRedirection component={<ForgotPassword />} />}
      />
      {/* route with auth */}
      <Route
        path="/"
        element={<PrivateRoute component={<ConnectedLayout />} />}
      >
        <Route
          path="/register-onboarding"
          element={<RegisterOnBoarding route="register-onboarding" />}
        />
        <Route index path="/" element={<Navigate to="/dashboard" replace />} />
        <Route index path="dashboard" element={<Dashboard />} />
        <Route path="orders" element={<Orders />} />
        <Route path="establishment" element={<Establishment />} />
        <Route path="establishment/edit" element={<EditEstablishment />} />
        <Route path="establishment/product/new" element={<Product />} />
        <Route path="establishment/product/edit/:id" element={<Product />} />
        <Route path="reviews" element={<Reviews />} />
        <Route path="account" element={<Account />} />
      </Route>
      <Route path="/*" element={<AuthRedirection />} />
    </Routes>
  );
};

export default Router;
