import { Box, CssBaseline, Grid } from "@mui/material";

const NotConnectedLayout = (props) => {
  return (
    <Grid
      container
      item
      component="main"
      sx={{
        inset: 0,
        position: "fixed",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "white",
      }}
    >
      <CssBaseline />
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          backgroundColor: "white",
          height: "100vh",
        }}
      >
        {props.children}
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={6.2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          paddingLeft: 0,
          height: "100vh",
          top: 0,
          position: "sticky",
        }}
      >
        <Box
          sx={{
            backgroundImage: "url('/images/login-image.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
            width: "100%",
            borderRadius: "60px",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default NotConnectedLayout;
