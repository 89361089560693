import {  TableBody } from "@mui/material";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const TableDragAndDropList = ({
  items,
  dragabbleKeyName,
  renderItem,
  onItemsChange,
  containerSx,
}) => {
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const newItems = [...items];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    
    onItemsChange(newItems);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} sx={{ overflow: "hidden" }}>
      <Droppable droppableId="droppable" style={{ overflow: "hidden"}}>
        {(provided, snapshot) => (
          <TableBody
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{  overflow: "hidden", ...containerSx}}
          >
            {items.map((item, index) => {
              return (
                <Draggable
                  draggableId={JSON.stringify(item)}
                  key={JSON.stringify(item)}
                  index={index}
                  style={{ overflow: "hidden", position: "relative" }}
                >
                  {(provided, snapshot) => {
                    if (snapshot.isDragging) {
                      provided.draggableProps.style.left = 0;
                      provided.draggableProps.style.top = 0;
                      provided.draggableProps.style.position = "relative";
                      provided.draggableProps.style.overflow = "hidden";
                    }
                    return renderItem(
                      item,
                      {
                        id: JSON.stringify(item),
                        ref: provided.innerRef,
                        ...provided.draggableProps,
                        ...provided.dragHandleProps,
                      },
                      index,
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TableDragAndDropList;