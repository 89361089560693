import React, { useReducer } from "react";
import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFirebase } from "../hooks/useFirebase";
import formReducer from "../utils/formReducer";
import { useNavigate, useHi } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useCookies } from "react-cookie";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import Checkbox from "../components/ui/Checkbox";
import NotConnectedLayout from "../components/NotConnectedLayout";
import { collection, getDocs, query, where } from "firebase/firestore";

const Login = () => {
  const { auth, db, setEstablishment } = useFirebase();
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(formReducer, {});
  const [rememberMe, setRememberMe] = React.useState(false);

  const onSubmit = async () => {
    try {
      if (!state.email) {
        enqueueSnackbar("Merci de renseigner un email !", {
          variant: "error",
        });
        return;
      }
      if (state.password < 3) {
        enqueueSnackbar("Merci de renseigner un mot de passe !", {
          variant: "error",
        });
        return;
      }
      const q = query(
        collection(db, "establishments"),
        where("email", "==", state.email)
      );
      const establishment = await getDocs(q);

      if (establishment.empty) throw new Error("Establishment not found");

      const response = await signInWithEmailAndPassword(
        auth,
        state.email,
        state.password
      );
      console.log(response.user);
      console.log({
        id: establishment.docs[0].id,
        ...establishment.docs[0].data(),
      });
      setEstablishment({
        id: establishment.docs[0].id,
        ...establishment.docs[0].data(),
      });

      setCookie("establishmentToken", establishment.docs[0].id, {
        path: "/",
        maxAge: 86400,
      });

      navigate("/dashboard");
    } catch (error) {
      if (error.message === "Establishment not active")
        enqueueSnackbar("Votre restaurant n'est pas encore été activé", {
          variant: "error",
        });
      else
        enqueueSnackbar("L'adresse mail ou le mot de passe est invalide", {
          variant: "error",
        });
      return;
    }
  };

  return (
    <NotConnectedLayout>
      <Box
        component="div"
        sx={{
          display: "flex",
          flex: 1,
          width: "80%",
          paddingY: "60px",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
            width: "80%",
            maxWidth: 500,
          }}
        >
          <img src="/logo.svg" alt="logo" style={{ width: 100, height: 100 }} />
          <Box>
            <Typography
              component="h1"
              variant="h5"
              style={{
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 50,
                color: "#091B40",
              }}
            >
              Bienvenue
            </Typography>

            <Box
              sx={{
                marginTop: "44px",
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <Input
                label="Email"
                placeholder="Entrez votre email"
                type="email"
                autoComplete="email"
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "email",
                    payload: e.target.value,
                  })
                }
              />
              <Input
                label="Mot de passe"
                placeholder="Entrez votre mot de passe"
                type="password"
                autoComplete="current-password"
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "password",
                    payload: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Checkbox
                label="Se souvenir"
                checked={rememberMe}
                onChange={(value) => setRememberMe(value)}
              />
              <Typography
                onClick={() => navigate("/forgot")}
                component="p"
                variant="body1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#FE765E",
                  cursor: "pointer",
                }}
              >
                Mot de passe oublié ?
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="contained"
              label="Se connecter"
              color="primary"
              onClick={onSubmit}
              sx={{
                marginTop: 2,
              }}
              fullWidth
            />
            <Typography
              component="p"
              variant="body1"
              style={{
                fontFamily: "Poppins",
                fontSize: 16,
                color: "#091B40",
                marginTop: "30px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                gap: 5,
              }}
            >
              Vous n'avez pas de compte ?{" "}
              <Typography
                onClick={() => navigate("/register")}
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FE765E",
                  cursor: "pointer",
                }}
              >
                S'inscrire
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </NotConnectedLayout>
  );
};

export default Login;
