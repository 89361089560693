import {
  BarChart,
  Description,
  LocationOn,
  Phone,
  Schedule,
  StarRounded,
} from "@mui/icons-material";
import { Box, ButtonBase, Typography } from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router";
import Header from "../components/Header";
import Button from "../components/ui/Button";
import { useFirebase } from "../hooks/useFirebase";
import { DAYS, sortSchedulesByDayAndTime } from "../utils/common";

const Establishment = () => {
  const navigate = useNavigate();
  const { products, establishment, establishmentRef } = useFirebase();

  const toggleOpening = async () => {
    await updateDoc(establishmentRef, {
      isOpen: !establishment?.isOpen,
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        sx={{
          paddingTop: 8,
          paddingLeft: 4,
          paddingRight: 8,
        }}
      />
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          paddingLeft: 4,
          paddingRight: 8,
          paddingBottom: 8,
          marginTop: 8,
          display: "flex",
          flexDirection: "row",
          gap: 4,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            maxWidth: 300,
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: 2,
              filter: "drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.09))",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <img
                src={
                  establishment?.image ||
                  "https://firebasestorage.googleapis.com/v0/b/dolphin-deliv.appspot.com/o/no-image.jpg?alt=media&token=96361e81-97f7-4c77-9f56-21fb380ba6e4"
                }
                alt="logo"
                style={{ width: 55, height: 55, borderRadius: 999 }}
              />
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#091B40",
                }}
              >
                {establishment?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: 1.5,
                height: "1px",
                backgroundColor: "#70707029",
                marginBottom: "10px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <LocationOn sx={{ color: "#091B40" }} />
              <Typography
                variant="h4"
                sx={{ fontFamily: "Poppins", fontSize: 16, color: "#091B40" }}
              >
                {establishment?.address}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <Phone sx={{ color: "#091B40" }} />
              <Typography
                variant="h4"
                sx={{ fontFamily: "Poppins", fontSize: 16, color: "#091B40" }}
              >
                {establishment?.phone}
              </Typography>
            </Box>
            <Button
              onClick={() => navigate("/establishment/edit")}
              label="Modifier"
              fullWidth={false}
              sx={{
                height: 42,
                width: 126,
                marginTop: 2,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: 2,
              filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <Description sx={{ color: "#091B40" }} />
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#091B40",
                  fontWeight: 600,
                  textAlign: "center",
                  width: 20,
                }}
              >
                Informations
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <StarRounded sx={{ color: "#091B40" }} />
              <Typography
                variant="h4"
                sx={{ fontFamily: "Poppins", fontSize: 16, color: "#091B40" }}
              >
                {(
                  (establishment.totalCookingRate +
                    establishment.totalPresentationRate +
                    establishment.totalQualityPriceRatioRate) /
                    establishment.nbReviews /
                    3 || 0
                ).toFixed(1)}
                /5
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <BarChart sx={{ color: "#091B40" }} />
              {establishment?.nbReviews ? (
                <>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 16,
                      color: "#091B40",
                    }}
                  >
                    {establishment?.nbReviews}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      color: "#C7C8C9",
                    }}
                  >
                    notes
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "Poppins", fontSize: 16, color: "#091B40" }}
                >
                  aucune note
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: 2,
              filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <Schedule sx={{ color: "#091B40" }} />
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#091B40",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Horaires
              </Typography>
              <Button
                onClick={toggleOpening}
                label={establishment?.isOpen ? "Fermer" : "Ouvrir"}
                size="small"
                fullWidth={true}
                sx={{
                  height: 38,
                }}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
                height: "1px",
                backgroundColor: "#70707029",
              }}
            />
            {establishment.schedules.length > 0 ? (
              sortSchedulesByDayAndTime(establishment.schedules).map(
                (schedule, index) => (
                  <Box
                    key={index + JSON.stringify(schedule)}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 16,
                        color: "#091B40",
                        fontWeight: 600,
                        textAlign: "left",
                        width: 90,
                      }}
                    >
                      {DAYS.find((day) => day.value === schedule.day)?.label}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 16,
                        color: "#091B40",
                      }}
                    >
                      {/* 1400 to 14h00 */}
                      {schedule.open.toString().slice(0, 2) +
                        "h" +
                        schedule.open.toString().slice(2)}{" "}
                      à{" "}
                      {schedule.close.toString().slice(0, 2) +
                        "h" +
                        schedule.close.toString().slice(2)}
                    </Typography>
                  </Box>
                ),
              )
            ) : (
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 16,
                  color: "#091B40",
                  textAlign: "center",
                }}
              >
                Aucun horaire
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontFamily: "Poppins", fontSize: 30, fontWeight: 500 }}
            >
              Plats
            </Typography>
            <Button
              onClick={() => navigate("/establishment/product/new")}
              label="Ajouter un plat"
              size="small"
            />
          </Box>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 6,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: 8,
                  width: 8,
                  borderRadius: 8,
                  backgroundColor: "#72D983",
                  marginRight: 1.5,
                }}
              />
              Publié
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: 8,
                  width: 8,
                  borderRadius: 8,
                  backgroundColor: "#FE765E",
                  marginRight: 1.5,
                }}
              />
              Non publié
            </Box>
          </Box>
          {products.length > 0 ? (
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
                flexWrap: "wrap",
                overflowY: "auto",
              }}
            >
              {products.map((product, index) => (
                <Box
                  key={index + product?.name}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 1.5,
                    paddingBottom: 2.5,
                    borderRadius: "15px",
                    width: 300,
                    backgroundColor: "white",
                    filter: "drop-shadow(0px 3px 4px rgba(9, 27, 64, 0.11))",
                  }}
                >
                  <img
                    src={product?.image}
                    alt={product?.name}
                    style={{ width: "100%", height: 128, objectFit: "contain" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 1.5,
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {product?.name}
                    </Typography>
                    <Box
                      sx={{
                        height: 8,
                        width: 8,
                        borderRadius: 8,
                        backgroundColor: product?.isPublish
                          ? "#72D983"
                          : "#FE765E",
                        marginRight: 1.5,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 2.5,
                      width: "100%",
                    }}
                  >
                    <ButtonBase
                      sx={{
                        backgroundColor: "#FE765E",
                        borderRadius: "15px",
                        width: "100%",
                        paddingX: 2,
                        height: 38,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        color: "white",
                      }}
                      onClick={() =>
                        navigate(`/establishment/product/edit/${product.id}`)
                      }
                    >
                      Modifier le plat
                    </ButtonBase>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontFamily: "Poppins", fontSize: 16, fontWeight: 500 }}
              >
                Vous n'avez pas encore ajouté de plat
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Establishment;
