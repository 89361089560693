import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { useFirebase } from "../hooks/useFirebase";
import { LocalPhone, Person, Place } from "@mui/icons-material";
import OrderButtons from "../components/OrderButtons";
import useSound from "use-sound";

const MENUS = ["Nouvelles", "Attente du livreur", "Décalées", "Terminées"];
const DAY_HOURS = 24 * 60 * 60 * 1000;

const ORDER_STATUS = [
  "waitingValidation",
  "inPreparation",
  "waitingDelivery",
  "inDelivery",
  "delivered",
  "refused",
  "cancelled",
  "delayed",
];

const SWITCH_QUERY_STATUS = {
  [MENUS[0]]: [ORDER_STATUS[0], ORDER_STATUS[1]],
  [MENUS[1]]: [ORDER_STATUS[2]],
  [MENUS[2]]: [ORDER_STATUS[7]],
  [MENUS[3]]: [ORDER_STATUS[3], ORDER_STATUS[4]],
};

const ORDER_STATUS_TRANSLATION = {
  [ORDER_STATUS[0]]: "En attente de validation",
  [ORDER_STATUS[1]]: "Préparation en cours",
  [ORDER_STATUS[2]]: "En attente du livreur",
  [ORDER_STATUS[3]]: "En livraison",
  [ORDER_STATUS[4]]: "Livrée",
  [ORDER_STATUS[5]]: "Refusée",
  [ORDER_STATUS[6]]: "Annulée",
  [ORDER_STATUS[7]]: "Décalée",
};

const EMPTY_ORDER_TEXT = {
  [MENUS[0]]: "Aucune nouvelle commande",
  [MENUS[1]]: "Aucune commande en attente du livreur",
  [MENUS[2]]: "Aucune commande en décalée",
  [MENUS[3]]: "Aucune commande en terminée",
};

const Orders = () => {
  const { db, establishment } = useFirebase();
  const [play, { stop }] = useSound("sound/alert.mp3", { loop: true });
  const [activeMenu, setActiveMenu] = useState(MENUS[0]);
  const [orders, setOrders] = useState([]);

  const [selectedOrder, setSelectedOrder] = useState(null);

  let unsubscribe;

  useEffect(() => {
    if (!selectedOrder) return;

    // if selectedOrder.status in not in SWITCH_QUERY_STATUS[activeMenu] then set selectedOrder to null
    if (!SWITCH_QUERY_STATUS[activeMenu].includes(selectedOrder.status)) {
      setSelectedOrder(null);
      return;
    }
  }, [activeMenu, selectedOrder]);

  useEffect(() => {
    if (unsubscribe) unsubscribe();

    unsubscribe = getOrders(SWITCH_QUERY_STATUS[activeMenu]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (unsubscribe) unsubscribe();
      if (stop) stop();
    };
  }, [play, activeMenu]);

  const getOrders = (statusQuery) => {
    const ordersQuery = query(
      collection(db, "orders"),
      where(
        "establishmentRef",
        "==",
        doc(db, "establishments", establishment.id)
      ),
      // where date is in the last 4 hours
      where("date", ">=", new Date(new Date().getTime() - DAY_HOURS)),
      // where("date", "<=", new Date()),
      where("status", "in", statusQuery),
      orderBy("date", "desc")
    );
    return onSnapshot(ordersQuery, (snapshot) => {
      const newOrders = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        ref: doc.ref,
      }));
      const hasOneNewOrder = newOrders.some(
        (order) => order.status === "waitingValidation"
      );

      if (hasOneNewOrder) {
        play();
      } else {
        stop();
      }

      setOrders(newOrders);
      setSelectedOrder(newOrders[0] || null);
    });
  };

  const formatDateTime = (date) => {
    let response = "";
    if (new Date().getDay() !== new Date(date.toDate()).getDay())
      response += `Le ${
        new Date(date.toDate()).toLocaleString().split(" ")[0]
      } à `;

    response += `${new Date(date.toDate()).getHours()}:${
      new Date(date.toDate()).getMinutes() < 10 ? "0" : ""
    }${new Date(date.toDate()).getMinutes()}`;

    return response;
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        sx={{
          paddingTop: 8,
          paddingLeft: 4,
          paddingRight: 8,
          paddingBottom: 1,
        }}
      />
      <Box
        sx={{
          paddingTop: 8,
          paddingLeft: 4,
          paddingRight: 8,
          paddingBottom: 8,
          display: "flex",
          flex: 1,
          overflowY: "auto",
          // width: "100%",
          // height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            borderRadius: "30px",
            overflow: "hidden",
            backgroundColor: "#fff",
            filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15))",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              width: "300px",
              backgroundColor: "#091B40",
            }}
          >
            <Box
              sx={{
                marginTop: 6,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                gap: 4,
                flexWrap: "wrap",
                //overflowX: "auto",
                paddingX: 4,
                marginBottom: 4,
              }}
            >
              {MENUS.map((menu, index) => (
                <Box
                  key={menu + index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: activeMenu === menu ? "#fe765e" : "#fff",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => setActiveMenu(menu)}
                >
                  {menu}

                  <Box
                    sx={{
                      marginTop: 0.5,
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                      backgroundColor:
                        activeMenu === menu ? "#fe765e" : "transparent",
                    }}
                  />
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                {orders.length > 0 ? (
                  orders.map((order, index) => (
                    <Box
                      key={order.id + index}
                      sx={{
                        display: "flex",
                        // flex: 1,
                        flexDirection: "column",
                        paddingX: 4,
                        paddingY: 2,
                        cursor: "pointer",
                        borderTop: "1px solid #FCFDFF4D",
                        backgroundColor:
                          selectedOrder?.id === order.id
                            ? "white"
                            : "transparent",
                        color:
                          selectedOrder?.id === order.id ? "#091B40" : "#fff",
                        "&:last-child": {
                          borderBottom: "1px solid #FCFDFF4D",
                        },
                      }}
                      onClick={() => setSelectedOrder(order)}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                        }}
                      >
                        Commande {order.shortCode}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                        }}
                      >
                        {formatDateTime(order.date)}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {EMPTY_ORDER_TEXT[activeMenu]}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              backgroundColor: "white",
              overflowY: "auto",
            }}
          >
            {selectedOrder && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flex: 1,
                  gap: 4,
                  padding: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      marginTop: 4,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "#091B40",
                      }}
                    >
                      Commande
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: 2,
                      }}
                    >
                      {selectedOrder.items.map((product, index) => (
                        <Box
                          key={index + product.name}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            padding: 2,
                            backgroundColor: "#F5F5F5",
                            borderRadius: 4,
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              fontWeight: "medium",
                              color: "#091B40",
                            }}
                          >
                            {product.name}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 3,
                            }}
                          >
                            <Box
                              sx={{
                                width: "40%",
                                display: "flex",
                                flexDirection: "column",
                                gap: 0.5,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontFamily: "Poppins",
                                  color: "#091B40",
                                }}
                              >
                                Quantité : {product.quantity}
                              </Typography>
                              {product.size && (
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontFamily: "Poppins",
                                    color: "#091B40",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Taille : {product.size.name}
                                </Typography>
                              )}
                              {product.comment && (
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontFamily: "Poppins",
                                    color: "#091B40",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Commentaire : {product.comment}
                                </Typography>
                              )}
                            </Box>
                            {product.customizations && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 0,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontFamily: "Poppins",
                                    color: "#091B40",
                                  }}
                                >
                                  Personnalisation :
                                </Typography>
                                <ul
                                  style={{
                                    marginTop: -2,
                                  }}
                                >
                                  {product.customizations.map(
                                    (customization) => (
                                      <li
                                        key={customization.name}
                                        sx={{
                                          fontSize: "18px",
                                          fontFamily: "Poppins",
                                          color: "#091B40",
                                        }}
                                      >
                                        {customization.name} x{" "}
                                        {customization.quantity}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "#091B40",
                      }}
                    >
                      Prix total : {selectedOrder.total.toFixed(2)}€
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#091B40",
                        }}
                      >
                        Identifiant : {selectedOrder.shortCode}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#091B40",
                        }}
                      >
                        Client : {selectedOrder.client.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                          // fontWeight: "bold",
                          color: "#091B40",
                        }}
                      >
                        {formatDateTime(selectedOrder.date)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#091B40",
                        }}
                      >
                        Statut
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#FE765E",
                        }}
                      >
                        {ORDER_STATUS_TRANSLATION[selectedOrder.status]}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "1px",
                      width: "100%",
                      backgroundColor: "#70707026",
                      marginTop: 4,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#091B40",
                        }}
                      >
                        Client
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                      >
                        <LocalPhone sx={{ color: "#091B40" }} />
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            color: "#091B40",
                          }}
                        >
                          {selectedOrder.client.phone}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                      >
                        <Place sx={{ color: "#091B40" }} />
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            color: "#091B40",
                          }}
                        >
                          {selectedOrder.client.address.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#091B40",
                        }}
                      >
                        Livreur
                      </Typography>
                      {selectedOrder.deliverer ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                            }}
                          >
                            <Person sx={{ color: "#091B40" }} />
                            <Typography
                              sx={{
                                fontSize: "18px",
                                fontFamily: "Poppins",
                                color: "#091B40",
                              }}
                            >
                              {selectedOrder.deliverer.firstname}{" "}
                              {selectedOrder.deliverer.lastname}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                            }}
                          >
                            <LocalPhone sx={{ color: "#091B40" }} />
                            <Typography
                              sx={{
                                fontSize: "18px",
                                fontFamily: "Poppins",
                                color: "#091B40",
                              }}
                            >
                              {selectedOrder.deliverer.phone}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            color: "#091B40",
                          }}
                        >
                          Aucun assigné
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "1px",
                      width: "100%",
                      backgroundColor: "#707070",
                      marginTop: 6,
                    }}
                  />
                </Box>
                <OrderButtons
                  order={selectedOrder}
                  setOrder={setSelectedOrder}
                  status={selectedOrder.status}
                  ORDER_STATUS={ORDER_STATUS}
                  customer={selectedOrder.client}
                  clientRef={selectedOrder.clientRef}
                  deliverer={selectedOrder.deliverer}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Orders;
