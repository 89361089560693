import { Box, styled, Switch, Typography } from "@mui/material";
import React from "react";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(32px)",
      color: "#FCFDFF",

      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#091B40",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 24,
    height: 24,
    borderRadius: 32,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    width: 64,
    height: 32,
    borderRadius: 999,
    opacity: 1,
    backgroundColor: "#F5F6F8",
    boxSizing: "border-box",
  },
}));

const SwitchSecondary = (props) => {
  // const [checked, setChecked] = React.useState(false);

  // useEffect(() => {
  //   props.onChange(checked);
  // }, [checked]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: 16,
          fontWeight: 600,
          color: "#091B40",
        }}
      >
        {props.label}
      </Typography>
      <AntSwitch inputProps={{ "aria-label": "controlled" }} {...props} />
    </Box>
  );
};

export default SwitchSecondary;
