import { Box, InputBase, Typography } from "@mui/material";
import { useState } from "react";
import { useFirebase } from "../hooks/useFirebase";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";

const Header = (props) => {
  const { products } = useFirebase();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexDirection: "row",
        // width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        ...props.sx,
      }}
    >
      <Box component="div" sx={{ flex: 0.7, position: "relative" }}>
        <InputBase
          type="text"
          placeholder="Rechercher"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            borderRadius: 60,
            width: "100%",
            height: 82,
            paddingY: 3,
            paddingLeft: 10,
            paddingRight: 5,
            fontFamily: "Poppins",
            backgroundColor: "white",
            fontSize: 16,
            filter: "drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.09))",
            color: "#091B40",
            "::placeholder": {
              opacity: 0.8,
            },
          }}
        />
        {search.length > 0 && (
          <Box
            component="div"
            sx={{
              position: "absolute",
              top: 90,
              left: 0,
              right: 0,
              backgroundColor: "white",
              zIndex: 1,
              borderRadius: 12,
              paddingY: 3,
              paddingX: 6,
              boxShadow:
                "0px 4px 10px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Poppins",
                color: "#091B40",
                fontSize: 16,
                fontWeight: 600,
                marginBottom: 2,
              }}
            >
              Résultats de recherche
            </Typography>
            {filteredProducts.length === 0 ? (
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Poppins",
                  color: "#091B40",
                  fontSize: 14,
                  marginBottom: 2,
                }}
              >
                Aucun résultat
              </Typography>
            ) : (
              <Box
                component="div"
                sx={{
                  maxHeight: 350,
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {filteredProducts.map((product) => (
                  <Box
                    key={product.id}
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSearch("");
                      navigate(`/establishment/product/edit/${product.id}`);
                    }}
                  >
                    <img
                      src={product.image}
                      alt="product"
                      style={{ width: 48, height: 48 }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Poppins",
                        color: "#091B40",
                        fontSize: 14,
                      }}
                    >
                      {product.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
        <Box
          component="div"
          sx={{
            position: "absolute",
            left: 30,
            bottom: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="/svg/search-blue.svg"
            alt="rechercher"
            style={{
              width: 32,
              height: 32,
              fill: "#FE765E",
            }}
          />
        </Box>
        {search.length > 0 && (
          <Box
            component="div"
            sx={{
              position: "absolute",
              right: 30,
              bottom: 0,
              top: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setSearch("")}
            />
          </Box>
        )}
      </Box>

      <img
        src="/svg/bell-orange.svg"
        alt="logo"
        style={{ width: 32, height: 32 }}
      />
    </Box>
  );
};

export default Header;
