import { AddAPhotoRounded, CloseRounded } from "@mui/icons-material";
import {
  Box,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useEffect } from "react";
import { useReducer } from "react";
import { useNavigate } from "react-router";
import Header from "../components/Header";
import Button from "../components/ui/Button";
import Input from "../components/ui/Input";
import { useFirebase } from "../hooks/useFirebase";
import { useSnackbar } from "notistack";
import formReducer from "../utils/formReducer";
import { useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Select from "../components/ui/Select";
import InputPlacesAutocomplete from "../components/ui/InputPlacesAutocomplete";
import TableDragAndDropList from "../components/TableDragAndDropList";
import { DAYS, DAYS_INDEX, sortSchedulesByDayAndTime } from "../utils/common";

const EditEstablishment = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { storage, establishment, establishmentRef, db } = useFirebase();
  const [state, dispatch] = useReducer(formReducer, {});
  const [image, setImage] = useState(null);
  const [banner, setBanner] = useState(null);

  const [categories, setCategories] = useState([]);

  const [schedules, setSchedules] = useState([]);
  const [scheduleDays, setScheduleDays] = useState(DAYS[0].value);
  const [scheduleOpen, setScheduleOpen] = useState(null);
  const [scheduleClose, setScheduleClose] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await getDocs(collection(db, "categories"));
      setCategories(
        categories.docs.map((doc) => ({
          label: doc.data().title,
          value: doc.data().value,
        })),
      );
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (establishment) {
      dispatch({
        type: "set",
        payload: {
          name: establishment?.name,
          address: establishment?.address,
          phone: establishment?.phone,
          averagePrice: +establishment?.averagePrice,
          establishmentType: establishment?.establishmentType,
          preparationTime0: establishment?.preparationTime?.[0] || "",
          preparationTime1: establishment?.preparationTime?.[1] || "",
          _geoloc: {
            lat: +establishment._geoloc.lat,
            lng: +establishment._geoloc.lng,
          },
        },
      });

      setSchedules(establishment.schedules);
      setImage({ url: establishment.image });
      setBanner({ url: establishment.banner });
    }
  }, [establishment]);

  const addSchedule = async () => {
    if (!scheduleDays || !scheduleOpen || !scheduleClose)
      return enqueueSnackbar("Merci de renseigner tous les champs", {
        variant: "error",
      });

    const newSchedule = {
      dayIndex: DAYS_INDEX[scheduleDays],
      day: scheduleDays,
      open: +scheduleOpen.replace(":", ""),
      close: +scheduleClose.replace(":", ""),
    };

    setSchedules([...schedules, newSchedule]);

    setScheduleDays(DAYS[0].value);
    setScheduleOpen("00:00");
    setScheduleClose("00:00");
  };

  const handleRemoveSchedule = async (index) => {
    const newSchedules = [...schedules];

    newSchedules.splice(index, 1);

    setSchedules(newSchedules);
  };

  const handleSubmit = async () => {
    if (!state._geoloc) {
      return enqueueSnackbar("Merci de renseigner une adresse", {
        variant: "error",
      });
    }
    if (!state.preparationTime0 || !state.preparationTime1) {
      return enqueueSnackbar(
        "Merci de renseigner un temps de préparation moyen",
        {
          variant: "error",
        },
      );
    }
    if (!image && !establishment.image) {
      return enqueueSnackbar("Merci de renseigner une photo de profil", {
        variant: "error",
      });
    }
    if (!banner && !establishment.banner) {
      return enqueueSnackbar("Merci de renseigner une bannière", {
        variant: "error",
      });
    }
    try {
      let imageURL;
      if (establishment.image !== image?.url) {
        imageURL = await uploadFile(
          image.file,
          establishmentRef,
          "image" + image.type.replace("image/", "."),
        );
      }

      let bannerURL;
      if (establishment.banner !== banner?.url) {
        bannerURL = await uploadFile(
          banner.file,
          establishmentRef,
          "banner" + banner.type.replace("image/", "."),
        );
      }
      await updateDoc(establishmentRef, {
        name: state.name,
        address: state.address,
        _geoloc: {
          lat: +state._geoloc.lat,
          lng: +state._geoloc.lng,
        },
        image: imageURL || establishment.image,
        banner: bannerURL || establishment.banner,
        schedules: schedules,
        averagePrice: isNaN(state.averagePrice) ? 1 : +state.averagePrice,
        establishmentType: state.establishmentType,
        preparationTime: [+state.preparationTime0, +state.preparationTime1],
        isComplete: "active",
      });

      enqueueSnackbar("Votre établissement a bien été mis à jour", {
        variant: "success",
      });

      navigate("/establishment");
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFile = async (file, docRef, filename = file.name) => {
    try {
      const storageRef = ref(storage, `${docRef.path}/${filename}`);

      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      return Promise.resolve(url);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handlePhotoUpload = (e) => {
    const [file] = e.target.files;
    const url = URL.createObjectURL(file);

    setImage({ file: file, url: url, type: file.type });
  };

  const handleBannerUpload = (e) => {
    const [file] = e.target.files;
    const url = URL.createObjectURL(file);

    setBanner({ file: file, url: url, type: file.type });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        sx={{
          paddingTop: 8,
          paddingLeft: 4,
          paddingRight: 8,
        }}
      />
      <Stack
        direction={{ md: "column", lg: "row" }}
        spacing={4}
        justifyContent="space-between"
        sx={{
          height: "100%",
          overflowY: "auto",
          paddingLeft: 4,
          paddingRight: 8,
          paddingBottom: 8,
          marginTop: 4,
          paddingTop: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            backgroundColor: "white",
            height: "fit-content",
            paddingX: 4,
            paddingY: 6,
            borderRadius: "30px",
            filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
          }}
        >
          <Box
            onClick={() => navigate("/establishment")}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            <img src="/svg/arrow-back-orange.svg" alt="food" />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 16,
                color: "#FE765E",
              }}
            >
              Menus
            </Typography>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: 700,
                color: "#091B40",
              }}
            >
              Informations générales
            </Typography>
            <Box
              sx={{
                marginTop: "26px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 20,
                    color: "#091B40",
                  }}
                >
                  Photo
                </Typography>
                <input
                  type="file"
                  hidden
                  id="image"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                />
                <label
                  htmlFor="image"
                  style={{
                    display: "inline-block",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      width: 128,
                      height: 128,
                      borderRadius: "50%",
                      backgroundColor: "#F5F5F5",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      border: "3px solid #FE765E",
                      position: "relative",
                    }}
                  >
                    <img
                      src={
                        image?.url ||
                        "https://firebasestorage.googleapis.com/v0/b/dolphin-deliv.appspot.com/o/no-image.jpg?alt=media&token=96361e81-97f7-4c77-9f56-21fb380ba6e4"
                      }
                      alt="restaurant"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                    />
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: "#FE765E",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        borderRadius: "50%",
                        zIndex: 1,
                      }}
                    >
                      <AddAPhotoRounded
                        sx={{
                          color: "white",
                        }}
                      />
                    </Box>
                  </Box>
                </label>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 20,
                    color: "#091B40",
                  }}
                >
                  Bannière
                </Typography>
                <input
                  type="file"
                  hidden
                  id="banner"
                  accept="image/*"
                  onChange={handleBannerUpload}
                />
                <label
                  htmlFor="banner"
                  style={{
                    display: "inline-block",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      width: 256,
                      height: 128,
                      borderRadius: "16px",
                      backgroundColor: "#F5F5F5",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      border: "3px solid #FE765E",
                      position: "relative",
                    }}
                  >
                    <img
                      src={
                        banner?.url ||
                        "https://firebasestorage.googleapis.com/v0/b/dolphin-deliv.appspot.com/o/no-image.jpg?alt=media&token=96361e81-97f7-4c77-9f56-21fb380ba6e4"
                      }
                      alt="banner"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "13px",
                      }}
                    />
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: "#FE765E",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: -14,
                        right: -14,
                        borderRadius: "50%",
                        zIndex: 1,
                      }}
                    >
                      <AddAPhotoRounded
                        sx={{
                          color: "white",
                        }}
                      />
                    </Box>
                  </Box>
                </label>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: 6,
                gap: 4,
              }}
            >
              <Input
                placeholder="Nom du restaurant"
                value={state?.name || ""}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "name",
                    payload: e.target.value,
                  })
                }
                containerSx={{
                  width: "80%",
                }}
              />
              <InputPlacesAutocomplete
                placeholder="Adresse"
                containerSx={{
                  width: "80%",
                }}
                value={state?.address || ""}
                onSelect={(address) => {
                  dispatch({
                    type: "field",
                    field: "address",
                    payload: address.name,
                  });
                  dispatch({
                    type: "field",
                    field: "_geoloc",
                    payload: address.location,
                  });
                }}
              />
              <Input
                placeholder="Téléphone"
                value={state?.phone || ""}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "phone",
                    payload: e.target.value,
                  })
                }
                containerSx={{
                  width: "80%",
                }}
              />
              <Select
                label="Type d'établissement"
                showLabel={false}
                options={categories}
                value={state?.establishmentType}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "establishmentType",
                    payload: e.target.value,
                  })
                }
                containerSx={{
                  width: "80%",
                }}
              />
              {/* <Select
                label="Prix moyen"
                showLabel={false}
                options={[
                  { label: "Peu chère (>10€)", value: 1 },
                  { label: "Moyen (~12€)", value: 2 },
                  { label: "Chère (<17€)", value: 3 },
                ]}
                value={state?.averagePrice || 1}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "averagePrice",
                    payload: +e.target.value,
                  })
                }
                containerSx={{
                  width: "80%",
                }}
              /> */}
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 20,
                    color: "#091B40",
                  }}
                >
                  Temps de préparation moyen (en minutes)
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Input
                    placeholder="minimum"
                    type="number"
                    value={state?.preparationTime0 || ""}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        field: "preparationTime0",
                        payload: e.target.value,
                      })
                    }
                  />
                  et
                  <Input
                    placeholder="maximum"
                    type="number"
                    value={state?.preparationTime1 || ""}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        field: "preparationTime1",
                        payload: e.target.value,
                      })
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "fit-content",

            marginRight: 8,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              paddingX: 4,
              paddingY: 6,
              borderRadius: "30px",
              filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: 700,
                color: "#091B40",
              }}
            >
              Horaires
            </Typography>
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Select
                  label="Jour"
                  showLabel={false}
                  options={DAYS}
                  value={scheduleDays}
                  onChange={(e) => setScheduleDays(e.target.value)}
                  containerSx={{
                    width: "80%",
                  }}
                />
                <Input
                  containerSx={{ width: "80%" }}
                  type="time"
                  value={scheduleOpen}
                  onChange={(e) => setScheduleOpen(e.target.value)}
                />
                <Input
                  containerSx={{ width: "80%" }}
                  type="time"
                  value={scheduleClose}
                  onChange={(e) => setScheduleClose(e.target.value)}
                />
                <Button
                  label="Ajouter"
                  onClick={addSchedule}
                  sx={{
                    width: 168,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </Box>

              {schedules.length > 0 ? (
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Jour</TableCell>
                        <TableCell>Ouverture</TableCell>
                        <TableCell>Fermeture</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableDragAndDropList
                      items={sortSchedulesByDayAndTime(schedules)}
                      onItemsChange={setSchedules}
                      dragabbleKeyName="name"
                      containerSx={{}}
                      renderItem={(item, props, index) => (
                        <TableRow {...props}>
                          <TableCell sx={{ fontSize: 16, color: "#091B40" }}>
                            {DAYS.find((day) => day.value === item.day)?.label}
                          </TableCell>
                          <TableCell sx={{ fontSize: 16, color: "#091B40" }}>
                            {item.open}
                          </TableCell>{" "}
                          <TableCell sx={{ fontSize: 16, color: "#091B40" }}>
                            {item.close}
                          </TableCell>
                          <TableCell
                            onClick={() => handleRemoveSchedule(index)}
                          >
                            <CloseRounded sx={{ color: "#FE765E" }} />
                          </TableCell>
                        </TableRow>
                      )}
                    />
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  textAlign={{ xs: "center", xl: "left" }}
                  sx={{ fontSize: 16, color: "#091B40" }}
                >
                  Aucun horaire ajouté
                </Typography>
              )}
            </Box>
          </Box>
          <Button
            label="Enregistrer"
            sx={{
              marginTop: 4,
              width: 168,
              display: "flex",
              marginLeft: "auto",
            }}
            size="small"
            onClick={handleSubmit}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default EditEstablishment;
