function formReducer(state, action) {
  switch (action.type) {
    case "field": {
      return {
        ...state,
        [action.field]: action.payload,
      };
    }
    case "set": {
      return { ...action.payload };
    }
    default:
      return state;
  }
}

export default formReducer;
