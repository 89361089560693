import { Box, Checkbox as CheckboxMUIM, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, { useEffect } from "react";

const Checkbox = (props) => {
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    props.onChange(checked);
  }, [checked]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "11px",
      }}
    >
      <CheckboxMUIM
        {...props}
        id="qeqwq"
        name="qeqwq"
        sx={{
          width: 26,
          height: 26,
          border: 2,
          borderColor: "#FE765E",
          borderRadius: "5px",
          color: "white",
          backgroundColor: "white",
          "&.Mui-checked": {
            color: "white",
            backgroundColor: "#FE765E",
          },
          ...props.sx,
        }}
        onChange={(e) => setChecked(e.target.checked)}
        checkedIcon={<CheckIcon sx={{ fontSize: 20 }} />}
      />
      <Typography
        component="p"
        variant="body1"
        style={{
          fontFamily: "Poppins",
          fontSize: 16,
          color: "#091B40",
          cursor: "pointer",
        }}
        onClick={() => setChecked(!checked)}
      >
        {props.label}
      </Typography>
    </Box>
  );
};

export default Checkbox;
