import React, { useReducer } from "react";
import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFirebase } from "../hooks/useFirebase";
import formReducer from "../utils/formReducer";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import NotConnectedLayout from "../components/NotConnectedLayout";

const ForgotPassword = () => {
  const { auth } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(formReducer, {});

  const onSubmit = async () => {
    try {
      if (!state.email) {
        enqueueSnackbar("Merci de renseigner un email !", {
          variant: "error",
        });
        return;
      }
      await sendPasswordResetEmail(auth, state.email);
      enqueueSnackbar(
        "Un email vous a été envoyé pour réinitialiser votre mot de passe",
        {
          variant: "success",
        }
      );
      navigate("/login");
    } catch (error) {
      enqueueSnackbar("L'adresse mail est invalide", {
        variant: "error",
      });
      return;
    }
  };

  return (
    <NotConnectedLayout>
      <Box
        component="div"
        sx={{
          display: "flex",
          flex: 1,
          width: "80%",
          paddingY: "60px",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
            width: "80%",
            maxWidth: 500,
          }}
        >
          <img src="/logo.svg" alt="logo" style={{ width: 100, height: 100 }} />
          <Box>
            <Typography
              component="h1"
              variant="h5"
              style={{
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 50,
                color: "#091B40",
              }}
            >
              Mot de passe oublié
            </Typography>
            <Box
              sx={{
                marginTop: "44px",
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <Input
                label="Email"
                placeholder="Entrez votre email"
                type="email"
                autoComplete="email"
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "email",
                    payload: e.target.value,
                  })
                }
              />
            </Box>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="contained"
              label="Réinitialiser le mot de passe"
              color="primary"
              onClick={onSubmit}
              sx={{
                marginTop: 2,
              }}
              fullWidth
            />
            <Typography
              component="p"
              variant="body1"
              onClick={() => navigate("/login")}
              style={{
                fontFamily: "Poppins",
                fontSize: 16,
                color: "#091B40",
                marginTop: "30px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                gap: 5,
                cursor: "pointer",
              }}
            >
              Retour à la page de connexion
            </Typography>
          </Box>
        </Box>
      </Box>
    </NotConnectedLayout>
  );
};

export default ForgotPassword;
