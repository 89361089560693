import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
} from "victory";

const BarChart = ({data, height }) => {

  return (
    <VictoryChart
      domainPadding={{ x: 50 }}
      theme={VictoryTheme.material}
      padding={{ top: 30, bottom: 50, left: 40, right: 0 }}
      height={height}
    >
      <VictoryAxis
        tickValues={data.map((d) => d.label)}
        style={{
          tickLabels: {
            fontSize: 10,
            padding: 5,
            angle: -45,
            textAnchor: "end",
          },
          axis: { stroke: "none" },
        }}
      />
      <VictoryAxis dependentAxis style={{ axis: { stroke: "none" } }} tickValues={data.map((d) => d.value)} />
      <VictoryBar
        data={data}
        x="label"
        y="value"
        cornerRadius={{ top: 5, bottom: 5 }}
        style={{ data: { fill: "#091B40" } }}
        labels={({ datum }) => `Nombre de commandes : ${datum.orders}`}
        labelComponent={
          <VictoryTooltip
            style={{ fontSize: 8 }}
            flyoutStyle={{
              stroke: "#091B40",
              strokeWidth: 0.5,
              fill: "#ffffff",
            }}
            cornerRadius={2}
          />
        }
      />
    </VictoryChart>
  );
};

export default BarChart;
