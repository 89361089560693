import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useFirebase } from "../hooks/useFirebase";
import Button from "./ui/Button";
import axios from "axios";

const ModalStripe = () => {
  const { establishment } = useFirebase();

  const [open, setOpen] = useState(establishment?.status === "onboarding");
  const [loading, setLoading] = useState(false);

  const handleClose = () => setOpen(false);

  const handleStripeRedirect = () => {
    setLoading(true);
    axios
      .get(
        `https://dolphin-api.fly.dev/stripe/resumeSetup/${establishment.stripeAccountId}`,
      )
      .then((res) => {
        if (res.data.url) {
          window.open(res.data.url, "_blank");
        }
      });
    setLoading(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          maxWidth: 600,
          bgcolor: "white",
          border: "none",
          borderRadius: "16px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Il nous manque des informations !
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Pour pouvoir être visible et payé sur Dolphin Deliv, vous devez
          renseigner des informations complémentaires sur votre compte (IBAN,
          SIRET, etc.).
        </Typography>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            label="Plus tard"
            color="primary"
            size="small"
            onClick={handleClose}
            disabled={loading}
            sx={{
              backgroundColor: "gray",
            }}
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            label="Valider mon compte"
            color="primary"
            size="small"
            onClick={handleStripeRedirect}
            disabled={loading}
            sx={{}}
            fullWidth
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalStripe;
