import React from "react";
import { Box, Typography } from "@mui/material";
import NotConnectedLayout from "../components/NotConnectedLayout";
import Button from "../components/ui/Button";
import { useNavigate } from "react-router";

const RegisterSuccess = () => {
  const navigate = useNavigate();

  return (
    <NotConnectedLayout>
      <Box
        component="div"
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            component="h1"
            variant="body1"
            sx={{
              fontFamily: "Poppins",
              fontSize: 30,
              fontWeight: "bold",
              color: "#091B40",
              marginBottom: 8,
            }}
          >
            Félicitation !
          </Typography>

          <Typography
            component="p"
            variant="body1"
            sx={{ fontFamily: "Poppins", fontSize: 16, color: "#091B40", textAlign: "center" }}
          >
            Votre demande d'inscription a bien été prise en compte. Nous traiterons votre
            candidtaure dans les plus bref délais et vous informera de sa validation ou non par
            mail.
          </Typography>
          <Button label="Retour à l'écran de connexion" onClick={() => navigate("/login")} sx={{
            marginTop: 6.5,
          }} />
        </Box>
      </Box>
    </NotConnectedLayout>
  );
};

export default RegisterSuccess;
