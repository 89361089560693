import { Box, Grid, SwipeableDrawer } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { HomeRounded } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
const menus = {
  title: "CV Back Office",
  items: [
    {
      title: "Accueil",
      icon: <HomeRounded color="#091b40" />,
      selectionIcon: <HomeRounded color="white" />,
      path: "/dashboard",
    },
    {
      title: "Commandes",
      icon: (
        <img
          src="/svg/order-blue.svg"
          alt="order"
          style={{ width: 18, height: 18 }}
        />
      ),
      selectionIcon: (
        <img
          src="/svg/order-white.svg"
          alt="order"
          style={{ width: 18, height: 18 }}
        />
      ),
      path: "/orders",
    },
    {
      title: "Établissement",
      icon: (
        <img
          src="/svg/establishment-blue.svg"
          alt="establishment"
          style={{ width: 18, height: 18 }}
        />
      ),
      selectionIcon: (
        <img
          src="/svg/establishment-white.svg"
          alt="establishment"
          style={{ width: 18, height: 18 }}
        />
      ),
      path: "/establishment",
    },
    {
      title: "Notation",
      icon: <StarRateRoundedIcon color="#091b40" />,
      selectionIcon: <StarRateRoundedIcon color="white" />,
      path: "/reviews",
    },
    {
      title: "Mon compte",
      icon: (
        <img
          src="/svg/user-blue.svg"
          alt="user"
          style={{ width: 18, height: 18 }}
        />
      ),
      selectionIcon: (
        <img
          src="/svg/user-white.svg"
          alt="user"
          style={{ width: 18, height: 18 }}
        />
      ),
      path: "/account",
    },
  ],
};
const drawerWidth = 256;
const DrawerContent = ({ setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();

  const logout = () => {
    setOpen(false);
    removeCookie("establishmentToken");
    navigate("/login");
  };
  return (
    <Box
      component="div"
      sx={{
        paddingLeft: 8,
        paddingRight: 1,
        paddingY: 8,
        // 128px (padding)
        height: "calc(100vh - 128px)",
        width: "256px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <img
          src="/logo.svg"
          alt="logo"
          style={{ width: 100, height: 100, marginBottom: 128 }}
        />

        <Box
          component="div"
          sx={{ display: "flex", flexDirection: "column", gap: "7px" }}
        >
          {menus.items.map((item, index) => (
            <Box
              key={item + index}
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                textAlign: "left",
                cursor: "pointer",
                fontFamily: "Poppins",
                color: item.path === location.pathname ? "white" : "#091B40",
                paddingLeft: "30px",
                fontSize: 16,
                gap: "18px",
                height: 84,
                borderRadius: 60,
                backgroundColor:
                  item.path === location.pathname ? "#FE765E" : "transparent",
              }}
              onClick={() => {
                navigate(item.path);
                setOpen(false);
              }}
            >
              {item.path === location.pathname ? item.selectionIcon : item.icon}
              {item.title}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          textAlign: "left",
          cursor: "pointer",
          fontFamily: "Poppins",
          color: "#091B40",
          paddingLeft: "30px",
          fontSize: 16,
          gap: "18px",
          height: 84,
          borderRadius: 60,
        }}
        onClick={logout}
      >
        <img
          src="/svg/power.svg"
          alt="logout"
          style={{ width: 18, height: 18 }}
        />
        Déconnexion
      </Box>
    </Box>
  );
};

const ConnectedLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Box
      component="div"
      sx={{
        inset: 0,
        position: "absolute",
        bgcolor: "#FCFDFF",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <DrawerContent setOpen={setMobileOpen} />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "none",
            xl: "none",
          },

          paddingTop: 11,
          paddingLeft: 5,
        }}
      >
        <MenuIcon
          onClick={handleDrawerToggle}
          sx={{
            width: 32,
            height: 32,
            color: "#FE765E",
          }}
        />
        <SwipeableDrawer onClose={handleDrawerToggle} open={mobileOpen}>
          <DrawerContent setOpen={setMobileOpen} />
        </SwipeableDrawer>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default ConnectedLayout;
