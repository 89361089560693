import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Input from "./Input";

const InputPlacesAutocomplete = (props) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyD3PFIpCNUCHanrus6QgjQeGw91kpbpu7s",
    language: "fr",
    options: {
      componentRestrictions: {
        country: ["fr"],
      },
      fields: ["geometry.location", "description"],
    },
  });

  const [address, setAddress] = useState(props.value);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setAddress(props.value);
  }, [props.value]);

  const handleSelect = (item) => {
    placesService.getDetails({ placeId: item.place_id }, (details) => {
      props.onSelect({
        name: item.description,
        location: {
          lat: details.geometry.location.lat(),
          lng: details.geometry.location.lng(),
        },
      });
    });
    setAddress(item.description);
    setIsFocused(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        ...props.containerSx,
      }}
    >
      <Input
        onChange={(evt) => {
          setAddress(evt.target.value);
          getPlacePredictions({ input: evt.target.value });
        }}
        onFocus={() => setIsFocused(true)}
        loading={isPlacePredictionsLoading}
        fullWidth
        inputProps={{
          autoComplete: "street-address",
          name: "address",
        }}
        {...props}
        containerSx={{}}
        value={address}
      />
      {placePredictions.length > 0 && isFocused && (
        <Box
          sx={{
            position: "absolute",
            top: "98%",
            flex: 1,
            backgroundColor: "white",
            border: 2,
            borderColor: "#091B40",
            borderRadius: 4,
            paddingY: 2,
            paddingX: 2,
            fontFamily: "Poppins",
            fontSize: 16,
            color: "#091B40",
            zIndex: 100,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
            Séléctionner une adresse
          </Typography>
          {placePredictions.map((item) => (
            <Box
              key={item.id}
              onClick={() => handleSelect(item)}
              sx={{
                cursor: "pointer",
                userSelect: "none",
                borderRadius: 2,
                paddingX: 2,
                paddingY: 1,
                "&:hover": {
                  backgroundColor: "#091B40",
                  color: "white",
                },
              }}
            >
              {item.description}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default InputPlacesAutocomplete;
