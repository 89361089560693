import { Close } from "@mui/icons-material";
import { Box, Modal, Typography } from "@mui/material";
import axios from "axios";
import { updateDoc } from "firebase/firestore";
import { useState } from "react";
import Button from "./ui/Button";
import Select from "./ui/Select";

const API_NOTIFICATIONS_URL = "https://dolphin-api.fly.dev/notifications/send";

const CANCEL_REASONS = [
  {
    label: "Trop de commandes",
    value: "Trop de commandes",
  },
  {
    label: "Certains produits ne sont plus disponibles",
    value: "Certains produits ne sont plus disponibles",
  },
  {
    label: "Autre",
    value: "Autre",
  },
];

const OrderButtons = ({
  customer,
  clientRef,
  deliverer,
  order,
  setOrder,
  status,
  ORDER_STATUS,
}) => {
  const [open, setOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState(CANCEL_REASONS[0]);

  const acceptOrder = async () => {
    axios.post(API_NOTIFICATIONS_URL, {
      title: "Commande validée",
      body: "Votre commande a été validée par le restaurateur. Elle est en cours de préparation.",
      userRefPath: clientRef.path,
    });
    await updateDoc(order.ref, {
      status: ORDER_STATUS[1],
    });

    setOrder({
      ...order,
      status: ORDER_STATUS[1],
    });
  };

  const refuseOrder = async () => {
    setOpen(false);

    const response = await axios.post(
      "https://dolphin-api.fly.dev/stripe/cancelSetupIntent",
      // "https://dolphin-api.fly.dev/dev/stripe/cancelSetupIntent",
      {
        paymentIntent: order.paymentIntent,
      }
    );
    axios.post(API_NOTIFICATIONS_URL, {
      title: "Commande annulée",
      body: "Nous sommes désolé, votre commande a été annulée par le restaurateur.",
      userRefPath: clientRef.path,
    });
    if (response.data.status === "succeeded") {
      await updateDoc(order.ref, {
        status: ORDER_STATUS[5],
        cancelReason: cancelReason,
      });

      setOrder(null);
    }
  };

  const delayOrder = async () => {
    const newDate = new Date(new Date().getTime() + 30 * 60 * 1000);
    axios.post(API_NOTIFICATIONS_URL, {
      title: "Commande décalée",
      body: "Votre commande a été décalée de 30 minutes par le restaurateur.",
      userRefPath: clientRef.path,
    });
    await updateDoc(order.ref, {
      date: newDate,
      status: ORDER_STATUS[7],
    });

    setOrder(null);
  };

  const validateOrder = async () => {
    axios.post(API_NOTIFICATIONS_URL, {
      title: "Commande en attente de livraison",
      body: "Votre commande est en attente du livreur.",
      userRefPath: clientRef.path,
    });
    await updateDoc(order.ref, {
      status: ORDER_STATUS[2],
    });

    setOrder({
      ...order,
      status: ORDER_STATUS[2],
    });
  };

  const OrderInDelivery = async () => {
    axios.post(API_NOTIFICATIONS_URL, {
      title: "Commande en livraison",
      body: "Votre commande a été remise au livreur.",
      userRefPath: clientRef.path,
    });
    await updateDoc(order.ref, {
      status: ORDER_STATUS[3],
    });

    setOrder({
      ...order,
      status: ORDER_STATUS[3],
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {status === ORDER_STATUS[0] && (
          <>
            <Button
              label="Accepter"
              sx={{
                paddingX: 4,
                paddingY: 1,
              }}
              size="small"
              onClick={acceptOrder}
            />
            <Button
              label="Décaler"
              sx={{
                paddingX: 4,
                paddingY: 1,
              }}
              size="small"
              onClick={delayOrder}
            />
            <Button
              label="Refuser"
              sx={{
                paddingX: 4,
                paddingY: 1,
              }}
              size="small"
              onClick={() => setOpen(true)}
            />
          </>
        )}
        {status === ORDER_STATUS[1] && (
          <Button
            label="Commande prête !"
            sx={{
              paddingX: 4,
              paddingY: 1,
            }}
            size="small"
            onClick={validateOrder}
          />
        )}
        {status === ORDER_STATUS[2] && deliverer && (
          <Button
            label="Commande donnée au livreur"
            sx={{
              paddingX: 4,
              paddingY: 1,
            }}
            size="small"
            onClick={OrderInDelivery}
          />
        )}
        {status === ORDER_STATUS[7] && (
          <Button
            label="Accepter la commande"
            sx={{
              paddingX: 4,
              paddingY: 1,
            }}
            size="small"
            onClick={acceptOrder}
          />
        )}
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            paddingX: 4,
            paddingY: 3,
            borderRadius: 4,
            height: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Refuser la commande
            </Typography>
            <Close
              onClick={() => setOpen(false)}
              sx={{
                cursor: "pointer",
              }}
            />
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Êtes-vous sûr de vouloir refuser la commande ?
          </Typography>
          <Select
            label="Raison du refus"
            options={CANCEL_REASONS}
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
              marginTop: 3,
            }}
          >
            <Button
              label="Annuler"
              sx={{
                backgroundColor: "white",
                color: "black",
              }}
              fullWidth
              size="small"
              onClick={() => setOpen(false)}
            />
            <Button
              label="Refuser"
              sx={{
                backgroundColor: "firebrick",
              }}
              fullWidth
              size="small"
              onClick={refuseOrder}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default OrderButtons;
