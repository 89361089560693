import { Box, InputBase, Typography } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";

const Input = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const heightBySize = {
    base: 69,
    small: 54,
  };

  return (
    <Box
      component="div"
      sx={{ position: "relative", width: props.fullWidth ? "100%" : "auto", ...props.containerSx }}
    >
      {props.label && (
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: 20,
            fontWeight: 700,
            color: "#091B40",
            marginBottom: "21px",
          }}
        >
          {props.label}
        </Typography>
      )}
      <InputBase
        sx={{
          border: 2,
          borderColor: "#091B40",
          borderRadius: 60,
          width: "100%",
          height: heightBySize[props.size || "base"],
          paddingY: 3,
          paddingX: 5,
          fontFamily: "Poppins",
          fontSize: 16,
          color: "#091B40",
          "::placeholder": {
            opacity: 0.8,
          },
          ...props.inputSx,
        }}
        inputProps={{
          type: props.type === "password" && showPassword ? "text" : props.type,
          "aria-label": "controlled",
        }}
        {...props}
      />
      {props.type === "password" &&
        (showPassword ? (
          <VisibilityOffIcon
            onClick={() => setShowPassword(!showPassword)}
            sx={{
              position: "absolute",
              right: 30,
              bottom: 18,
              width: 32,
              height: 32,
              fill: "#FE765E",
            }}
          />
        ) : (
          <VisibilityIcon
            onClick={() => setShowPassword(!showPassword)}
            sx={{
              position: "absolute",
              right: 30,
              bottom: 18,
              width: 32,
              height: 32,
              fill: "#FE765E",
            }}
          />
        ))}
    </Box>
  );
};

export default Input;
