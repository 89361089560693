import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const LastOrderCard = ({ order }) => {
  const [relativeTime, setRelativeTime] = useState("");

  useEffect(() => {
    const rtf1 = new Intl.RelativeTimeFormat("fr", { style: "long" });

    const getRelativeTime = (diffMinutes) => {
      if (diffMinutes > 59) {
        const diffHours = Math.round(diffMinutes / 60);

        if (diffHours > 23) {
          const diffDays = Math.round(diffHours / 24);
          return rtf1.format(-diffDays, "days");
        } else return rtf1.format(-diffHours, "hours");
      } else if (diffMinutes === 0) {
        return "À l'instant";
      } else {
        return rtf1.format(-diffMinutes, "minutes");
      }
    };

    let diffTime = Math.abs(order.date.toDate() - new Date());
    let diffMinutes = Math.round(diffTime / (1000 * 60));

    // 1000 * 60 = 1 minute
    const intervalForHours = 1000 * 60;

    // 1000 * 5 = 5 seconds
    const intervalForMinutes = 1000 * 5;

    setRelativeTime(getRelativeTime(diffMinutes));

    setInterval(
      () => {
        diffTime = Math.abs(order.date.toDate() - new Date());
        diffMinutes = Math.round(diffTime / (1000 * 60));

        setRelativeTime(getRelativeTime(diffMinutes));
      },
      diffMinutes > 59 ? intervalForHours : intervalForMinutes
    );
  }, [order]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 3,
        backgroundColor: "white",
        borderRadius: "30px",
        filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))",
        paddingY: 1.5,
        paddingX: 2,
        marginRight: 1,
      }}
    >
      {/* 
      <img
        src={order?.client?.profilPicture}
        alt="product"
        style={{
          width: 44,
          height: 44,
          borderRadius: 99,
        }}
      />
      
        */}

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 14,
            color: "#091B40",
          }}
        >
          {order?.client?.name}
        </Typography>
        <Typography
          sx={{
            marginTop: "3px",
            fontFamily: "Poppins",
            fontSize: 16,
            color: "#091B40",
          }}
        >
          {order.items.length} plats
        </Typography>
        <Typography
          sx={{
            marginTop: 2,
            fontFamily: "Poppins",
            fontSize: 12,
            color: "#959595",
          }}
        >
          {relativeTime}
        </Typography>
      </Box>
    </Box>
  );
};

export default LastOrderCard;
