import { Autocomplete, Box, TextField, Typography, createFilterOptions } from "@mui/material";

const filter = createFilterOptions();

const ComboBox = ({ options, value, onChange, ...props }) => {
  return (
    <Box
      component="div"
      sx={{ position: "relative", width: props.fullWidth ? "100%" : "auto", ...props.containerSx }}
    >
      {props.label && (
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: 16,
            fontWeight: 600,
            color: "#091B40",
            marginBottom: "18px",
          }}
        >
          {props.label}
        </Typography>
      )}
      {/* <Autocomplete
        disablePortal
        freeSolo
        id="combo-box-demo"
        options={options}
        sx={{
          borderRadius: "15px",
          height: 57,
          display: "flex",
          alignItems: "center",
          fontFamily: "Poppins",
          fontSize: 16,
          color: "#091B40",
          backgroundColor: "#F5F6F8",
          "::placeholder": {
            opacity: 0.8,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{
              "& fieldset": { border: "none" },
              width: "100%",
              fontFamily: "Poppins",
              fontSize: 16,
              color: "#091B40",
            }}
          />
        )}
        value={value}
        onChange={onChange}
      /> */}
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue.replace("Ajouter ", ""));
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          console.log(params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== "" && !isExisting) {
            filtered.push("Ajouter " + inputValue);
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option;
          }
          // Regular option
          return option;
        }}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        sx={{
          borderRadius: "15px",
          height: 57,
          display: "flex",
          alignItems: "center",
          fontFamily: "Poppins",
          fontSize: 16,
          color: "#091B40",
          backgroundColor: "#F5F6F8",
          "::placeholder": {
            opacity: 0.8,
          },
        }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{
              "& fieldset": { border: "none" },
              width: "100%",
              fontFamily: "Poppins",
              fontSize: 16,
              color: "#091B40",
            }}
          />
        )}
      />
    </Box>
  );
};

export default ComboBox;
