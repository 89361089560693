export const DAYS = [
  { label: "Lundi", value: "monday" },
  { label: "Mardi", value: "tuesday" },
  { label: "Mercredi", value: "wednesday" },
  { label: "Jeudi", value: "thursday" },
  { label: "Vendredi", value: "friday" },
  { label: "Samedi", value: "saturday" },
  { label: "Dimanche", value: "sunday" },
];

export const DAYS_INDEX = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

export const sortSchedulesByDayAndTime = (schedules) => {
  return schedules.sort((a, b) => {
    if (a.dayIndex < b.dayIndex) return -1;
    if (a.dayIndex > b.dayIndex) return 1;
    if (a.open < b.open) return -1;
    if (a.open > b.open) return 1;
    return 0;
  });
};
